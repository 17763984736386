import { Avatar, Dropdown, Menu, MenuProps, Space, Tag, Typography } from "antd";
import React from "react"
import {
  GlobalOutlined,
  LoginOutlined,
  LogoutOutlined,
  UserOutlined,
  MoonOutlined,
  SunOutlined,
} from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ActionTypes } from "@App/settings/reducers";
import { ApplicationState } from "@App/settings/StateManager";
import { useKeycloak } from "@App/settings/keycloak";
import { Theme } from "@AppRoot";
import { getUsersFirstLetters } from "../helpers";
// import { ThemeIcon } from "../buttons/DarkThemeToggle";


type MenuItems = Required<MenuProps>['items'];

const iconProps = {style:{ fontSize: '1.1rem' }};

export const ProfileMenu:React.FC<{items?:MenuProps['items']}> = (props) => {
  const { keycloak, userData } = useKeycloak();
  const { state, dispatch } = React.useContext(ApplicationState);
  const { t } = useTranslation('nav');
  const nav = useNavigate();

  const handleThemeToggle = () => {
    dispatch({
      type: ActionTypes.Change_theme,
      payload: {
        theme: state.theme === Theme.Light ? Theme.Dark : Theme.Light,
      },
    });
  };

  const handleLoginToggle = () => {
    if ( keycloak?.authenticated ) {
      keycloak?.logout();
    } else {
      keycloak?.login()
    }
  };

  const handleLangToggle = () => {
    dispatch({ type: ActionTypes.Toggle_language, payload: {} });
  };
  
  const settingsMenu: MenuItems = [
    {
      key: 'change-theme',
      onClick: handleThemeToggle,
      className: 'dark-toggler',
      title: t(`settings.theme.${state.theme !== Theme.Dark ? 'dark' : 'light'}`, state.theme as string),
      // icon: <ThemeIcon isDarkMode={state.theme == Theme.Dark} />,
      icon: state.theme !== Theme.Dark ? <MoonOutlined {...iconProps} /> : <SunOutlined {...iconProps} />,
      label: t('settings.theme.toggler'),
    },
    {
      key: 'change-lang',
      icon: <GlobalOutlined {...iconProps} />,
      onClick: handleLangToggle,
      title: t('settings.language.current') + state.language,
      label: t('settings.language.toggler'),
    },
    {
      key: 'log-in-out',
      icon: keycloak?.authenticated ? <LogoutOutlined {...iconProps} /> : <LoginOutlined {...iconProps} />,
      onClick: handleLoginToggle,
      label: keycloak?.authenticated ? t('buttons.logout', {username: userData?.username} ) : t('buttons.login'),
    },
    {
      key: 'user-profile',
      icon: <UserOutlined {...iconProps} />,
      onClick: () => nav('/profile'),
      label: ` ${userData?.firstName} ${userData?.lastName}`,
    },
  ];

  return true ? (
    <Menu
      items={props.items ?? settingsMenu}
      theme={state.theme !== 'auto' ? state.theme : 'dark'}
      style={{
        bottom: 4,
        position: 'sticky',
        justifySelf: 'end',
      }}
      // trigger={["hover"]}
      // placement="bottomLeft"
    
    />
  ) : (
    <Dropdown
      menu={{
        theme: state.theme !== 'auto' ? state.theme : 'dark',
        items: props.items ?? settingsMenu,
        style: {
          position: 'sticky',
          bottom: 4,
          justifySelf: 'end',
        }
      }}
      trigger={["hover"]}
      placement="bottomLeft"
    >
      {/* <div onClick={e => e.preventDefault()}> */}
        <Avatar size="large">
          { getUsersFirstLetters(userData) ?? <UserOutlined /> }
        </Avatar>
      {/* </div> */}
    </Dropdown>
  )
}
