import React, { useState, useRef } from 'react';
import { Radio, Button, Result, Card, Carousel } from 'antd';

type QuestionType = {
    question: string;
    options: string[];
    correctAnswer: number; // index of the correct option
}

const sampleQuestions: QuestionType[] = [
    {
        question: "What does GDPR stand for?",
        options: ["Global Data Protection Regulation", "General Data Processing Regulation", "General Data Protection Rule", "General Data Protection Regulation"],
        correctAnswer: 3
    },{
      question: "What does GDPR stand for?",
      options: ["Global Data Protection Regulation", "General Data Processing Regulation", "General Data Protection Rule", "General Data Protection Regulation"],
      correctAnswer: 3
  },{
    question: "What does GDPR stand for?",
    options: ["Global Data Protection Regulation", "General Data Processing Regulation", "General Data Protection Rule", "General Data Protection Regulation"],
    correctAnswer: 3
},
    // ... add more sample questions
];

type Props = {
    questions: QuestionType[];
}

const Questionnaire: React.FC<Props> = ({ questions }) => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState<(number | null)[]>(new Array(questions.length).fill(null));
    const [showResults, setShowResults] = useState(false);
    const carouselRef = useRef<Carousel>(null);

    const handleAnswer = (answerIndex: number) => {
        const newAnswers = [...answers];
        newAnswers[currentQuestion] = answerIndex;
        setAnswers(newAnswers);
    };

    const handleNext = () => {
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
            carouselRef.current?.next();
        } else {
            const score = answers.filter((answer, index) => answer === questions[index].correctAnswer).length;
            if (score === questions.length) setShowResults(true);
        }
    };

    const handlePrev = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion(currentQuestion - 1);
            carouselRef.current?.prev();
        }
    };

    return (
        <Card style={{ width: '80%', margin: '0 auto', marginTop: '20px' }}>
            {showResults ? (
                <Result 
                    status="success" 
                    title="Your Score" 
                    subTitle={`${answers.filter((answer, index) => answer === questions[index].correctAnswer).length} out of ${questions.length}`} 
                />
            ) : (
                <div>
                    <Carousel ref={carouselRef} dots={false} adaptiveHeight>
                        {questions.map((question, index) => (
                            <Question 
                                key={index}
                                data={question} 
                                selectedOption={answers[index]}
                                onOptionChange={(option) => handleAnswer(option)}
                            />
                        ))}
                    </Carousel>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <Button type="default" onClick={handlePrev} disabled={currentQuestion === 0}>
                            Previous
                        </Button>
                        <Button type="primary" onClick={handleNext} disabled={answers[currentQuestion] === null}>
                            {currentQuestion === questions.length - 1 ? "Submit" : "Next"}
                        </Button>
                    </div>
                </div>
            )}
        </Card>
    );
}

type QuestionProps = {
    data: QuestionType;
    selectedOption: number | null;
    onOptionChange: (option: number) => void;
}

const Question: React.FC<QuestionProps> = ({ data, selectedOption, onOptionChange }) => {
    return (
        <div>
            <h3>{data.question}</h3>
            <Radio.Group 
                options={data.options.map((option, index) => ({ label: option, value: index }))}
                onChange={e => onOptionChange(e.target.value)}
                value={selectedOption}
            />
        </div>
    );
}

export default Questionnaire;