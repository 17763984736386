import { LoaderFunctionArgs } from 'react-router-dom';
import React from 'react';

import config from "../config";


const backendUrl = ( config.backendUrl ?? '') + '/api';
/**
 * 
 * @returns {string} 
 */
function getToken(): string {
  // Retrieve the token from localStorage
  const sessionData = JSON.parse(localStorage.getItem('session') || '{}');
  const token = sessionData.token as string;

  // Check if token exists
  if (!token) {
    throw new Error('No token found');
  }

  return token;
}

export const fetchField = async ({params}: LoaderFunctionArgs<any>) => {
  const token = getToken();
  // Fetch the data using the token
  const response = await fetch(`${backendUrl}/v1/manage/field/${params.fieldItem || ''}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Response('Error fetching data', { status: response.status });
  }
  return await response.json() as {field: API.Field};
}

export const fetchUsers = async ({ params }: LoaderFunctionArgs<any>) => {
  const token = getToken();
  // Fetch the data using the token
  const response = await fetch(`${backendUrl}/v1/manage/user/${params.uuid}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Response('Error fetching data', { status: response.status });
  }

  return await response.json();
}

export const fetchAllForms = async (data: LoaderFunctionArgs<any>) => {
  const response = await fetch(`${backendUrl}/v1/forms/`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`,
    },
  });

  if (!response.ok) {
    throw new Response('Error fetching data', { status: response.status });
  }

  return await response.json() as API.GetForms;
}

export const fetchForm = async ({ params }: LoaderFunctionArgs<any>) => {
  const response = await fetch(`${backendUrl}/v1/form/${params.formIdentifier ?? ''}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`,
    },
  });

  if (!response.ok) {
    throw new Response('Error fetching data', { status: response.status });
  }

  return await response.json() as API.GetForms;
}

export const fetchFaculty = async ({ params }: LoaderFunctionArgs<any>) => {
  const response = await fetch(`${backendUrl}/v1/manage/faculty/${params.code ?? ''}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`,
    },
  });

  if (!response.ok) {
    throw new Response('Error fetching data', { status: response.status });
  }

  return await response.json() as API.GetForms;
}
