import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DndContext } from '@dnd-kit/core';
import {
  RedoOutlined, PlusOutlined, FieldNumberOutlined, QuestionCircleOutlined,
  ClearOutlined, DeleteRowOutlined, GlobalOutlined,
} from '@ant-design/icons';
import {  Table, Typography, Button, Tag, Space,  List, Switch, Grid, Select, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';

import type { TableLocale } from 'antd/es/table/interface';
import type { ColumnsType } from 'antd/es/table';
import type { DragEndEvent } from '@dnd-kit/core';

import { DraggableRow } from './DraggableRow';
import useTheme from '@App/settings/useTheme';
import { stringToColor } from './TableHelpers';


type LangKey = 'et' | 'en' | 'de' | 'ru' | string;

const valueMap = {
  label:{icon: <GlobalOutlined />},
  tooltip: {
    icon: <QuestionCircleOutlined />
  }
}
const renderLangTag = (lng: LangKey, record: AppFields.FieldType, key: keyof AppFields.FieldTranslationValues) => {
  const hasKey = key in record.settings;
  const title = hasKey ? record.settings[`${key}s`][lng] : record.settings[key];
  return (
    <Tag
      color={hasKey ? 'green': 'error'}
      title={title ?? 'No translation'}
    >
      {lng.toUpperCase()}
    </Tag>
  );
}

type TableIdentifiers = {
  id?: number;
  uuid?: string;
  name?: string;
}
// A utility type to check if all keys in T are never
type AtLeastOne<T, Keys = keyof T> = Keys extends keyof T
  ? T[Keys] extends never
    ? never : T
  : never;

type RequiredAdditionalProps = AtLeastOne<TableIdentifiers>;

interface DraggableTableProps extends RequiredAdditionalProps {
  currentForm?: Form.FormType | API.Form | null;
  allFields: AppFields.FieldType[];
  initialFields:  API.Form['fields'];
  onFieldsChange?: (fields: AppFields.FieldOrder[]) => void;
  onReset?: () => void;
};

const defaultDependency = {dependsOn: [], values: []};

export const DraggableTable: React.FC<DraggableTableProps> = ({
  currentForm,
  initialFields,
  allFields = [],
  onFieldsChange,
  ...rest
}) => {
  const [reset, setReset] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<AppFields.FieldType[]>(initialFields);
  const [dependencies, setDependencies] = React.useState<{ [key: number]: AppFields.FieldDependency }>({});
  const [requiredFields, setRequiredFields] = React.useState<number[]>([]);
  const { t } = useTranslation();
  const { md, lg } = Grid.useBreakpoint();
  const { isDark } = useTheme();

  const handleDelete = (id: number) => {
    if (dataSource.length) {
      setDataSource((p) => p.filter(i => i.id !== id));
    }
  };

  const clearList = () => {
    setDataSource([]);
  };

  const handleReset = () => {
    setReset(prev => !prev);
  };

  React.useEffect(() => {
    if (currentForm?.fields) {
      setDataSource(currentForm.fields);
      const initialRequired: Array<AppFields.FieldType['id']> = [];
      const initialDependencies: {[k: number]: AppFields.FieldDependency} = {};
      currentForm.fields.forEach((field) => {
        if (field.required) {
          initialRequired.push(field.id);
        }
        if (field.settings.dependencies) {
          initialDependencies[field.id] = field.settings.dependencies;
        }
      });
      setRequiredFields(initialRequired);
      setDependencies(initialDependencies);
    }
  }, [currentForm, reset]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === Number(active.id));
        const overIndex = previous.findIndex((i) => i.id === Number(over?.id));
        const newOrder = arrayMove(previous, activeIndex, overIndex);
        handleUpdateFields(newOrder); // Use combined callback
        return newOrder;
      });
    }
  };

  const handleSelectedFields = (fieldNames: string[]) => {
    // ... existing logic
    const fieldToAdd = allFields.find((field) => field.name === fieldNames[fieldNames.length - 1]);
    if (fieldToAdd) {
      setDataSource(prev => [...prev, fieldToAdd]);
    }
  }

  const handleRequired = (fieldProps: Form.FieldType, isChecked: boolean) => {
    setRequiredFields(
      prev => prev.includes(fieldProps.id)
        ? prev.filter(i => i !== fieldProps.id)
        : [ ...prev, fieldProps.id]
    )
  };

  type FieldGroups = {
    key: string,
    label: React.ReactNode,
    options: object[]
  }
  const groupedFields = React.useMemo(() => {
    let categories: FieldGroups[] = [];
    allFields.map((field) => {
      const groupHash = field.name.slice(0,5);
      const findGroup = categories.find(i => i.key == groupHash);
      const groupItem = {
        value: field.name,
        title: field.name,
        label: field.label
      };

      if (!findGroup) {
        categories.push({
          key: groupHash,
          label: (
            <Tag color={stringToColor(groupHash)} >
              {`${categories.length + 1}. ${field.name.split('_')[0]}`}
            </Tag>
          ),
          options: [groupItem]
        });
      }
      else {
        findGroup.options.push(groupItem);
      }
    })
    return categories;
  }, [])


  const handleUpdateFields = (newDataSource: AppFields.FieldType[]) => {
    const updatedFields: AppFields.FieldOrder[] = newDataSource.map((field, index) => ({
      form_id: currentForm?.id || 0,
      field_id: field.id,
      order: index + 1,
      required: requiredFields.includes(field.id),
      dependencies: dependencies[field.id] || null
    }));
  
    onFieldsChange && onFieldsChange(updatedFields);
  };

  const toggleDependency = (fieldId: number) => {
    setDependencies(prev => {
      // If the dependency currently exists, remove it.
      if (prev[fieldId]) {
        const { [fieldId]: removedDependency, ...rest } = prev;
        return rest;
      } 
      // If the dependency does not exist, add it with default values.
      else {
        return {
          ...prev,
          [fieldId]: defaultDependency
        };
      }
    });
  };
  const handleDependencyValues = (fieldId: number, values: string | string[], key: string ) => {
    setDependencies(prev => {
      const newValues = {
        [fieldId]: {
          ...prev[fieldId],
          [key]: values
        }
      };
      return {
        ...prev,
        ...newValues
      };
    });
  };

  const columns: ColumnsType<AppFields.FieldType> = [
    {
      key: 'field-order',
      dataIndex: 'order',
      width: 50,
      title: <FieldNumberOutlined style={{ fontSize: '1.2rem'}} />,
    },
    {
      key: 'field',
      title: t("table.column.label.formField"),
      dataIndex: 'name',
      width: '100%',
      render: (_, record, index) => {
        const color = record.type === 'draft' ? 'gold'
                    : record.type === 'active' ? 'green'
                    : record.type === 'deleted' ? 'red' : 'cyan';
        return (
          <>
            <List.Item.Meta
              style={{ textOverflow:'ellipsis' }}
              title={
                <Typography.Text style={{margin:0, whiteSpace:'break-spaces'}}>
                  {`${index+1}. `}{record.label}
                </Typography.Text>
              }
              description={
                <Typography.Paragraph style={{ opacity:.5, textOverflow:'ellipsis' }}>
                  {record.name}
                </Typography.Paragraph>
              }
            />
            <List.Item.Meta
              style={{ textOverflow:'ellipsis' }}
              description={
                <Space wrap align='baseline'>
                  <Tag color={color}>{record.type.toUpperCase()}</Tag>
                  { renderLangTag('et', record) }
                  { renderLangTag('en', record) }
                  {'de' in record.settings && renderLangTag('de', record) }
                  {'ru' in record.settings && renderLangTag('ru', record) }
                </Space>
              }
            />
            {
              record.id in dependencies &&
              (
              <List.Item.Meta
                title={
                  <Select
                    mode="multiple"
                    style={{ width: '100%', marginTop: '8px' }}
                    placeholder={t('forms.selectField', 'vali sõltuv väli')}
                    onChange={(v) => handleDependencyValues(record.id, v, 'dependsOn')}
                    defaultValue={record.settings.dependencies?.dependsOn}
                    options={
                      dataSource
                      .filter(field => field.id !== record.id)
                      .map(field => ({ value: field.name, label: field.label }))}
                  />
                }
                description={
                  <Select
                    mode="tags"
                    style={{ width: '100%', marginTop: '8px' }}
                    placeholder={t('forms.enterValues', 'väärtused')}
                    onChange={(e)=> handleDependencyValues(record.id, e, 'values')}
                    defaultValue={record.settings.dependencies?.values}
                    options={record.settings.options}
                  />
                }
              />
            )}
          </>
        )
      }
    },
    {
      key: 'field-rules',
      // title: t("table.column.conditional"),
      title: t("table.column.label.fieldRules"),
      dataIndex: 'settings',
      align: 'center',
      ellipsis: true,
      width: lg ? 220 : 120,
      render: (settings, record: Form.FieldType) => {
        return (
          <Space direction='vertical'>
            <Switch
              checked={requiredFields.includes(record.id)}
              onChange={(checked) => handleRequired(record, checked)}
              checkedChildren={t('forms.label.isRequired')}
              unCheckedChildren={t('forms.label.isNotRequired')}
            />
            <Switch
              checked={record.id in dependencies}
              onChange={(checked) => toggleDependency(record.id, checked)}
              checkedChildren={t('forms.label.isDependent')}
              unCheckedChildren={t('forms.label.isNotDependent')}
            />
        </Space>
        )
      },
    },
    {
      key: 'actions',
      // title: t("table.column.label.action"),
      align: 'end',
      ellipsis: false,
      width: lg ? 150 : 90,
      render: (_, record: Form.FieldType) => (
        <Button
          danger
          type='text'
          title={t('forms.btn.remove')}
          icon={<DeleteRowOutlined />}
          onClick={() => handleDelete(record.id)}
        >
          {lg && t('forms.btn.remove')}
        </Button>
      ),
    }
  ];

  const tableProps = {
    rowKey: "id",
    scroll: { x:600 },
    sticky: { offsetHeader: -24 },
    locale: {
      emptyText: t('table.texts.emptyText'),
      filterConfirm: t('table.texts.filterConfirm'),
      filterReset: t('table.texts.filterReset'),
      triggerAsc:  t('table.sort.asc'),
      triggerDesc: t('table.sort.desc'),
      cancelSort: t('table.sort.cancel'),
      filterSearchPlaceholder: t('table.texts.placeholders.filterFaculty'),
    },
  };

  React.useEffect( () => {
    if ( onFieldsChange ) {
      const newFieldsMappings = dataSource.map((field, index) => ({
        form_id: currentForm?.id || 0,
        field_id: field.id,
        order: index + 1,
        required: requiredFields.includes(field.id),
        dependencies: dependencies[field.id] || null
      }));
    
      onFieldsChange(newFieldsMappings);
    }
  }, [dataSource, requiredFields, dependencies]);

  return (
    <>
      <DndContext onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey='id'
          items={dataSource}
          strategy={verticalListSortingStrategy}
        >
          <div style={{position: 'relative', width: '100%'}}>
            <Table
              {...tableProps}
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              components={{
                body: { row: DraggableRow },
              }}
            />
            <div style={{
              position: 'sticky',
              bottom: -80,
              width: '100%',
              zIndex: 10,
              background: isDark ? '#1d1d1d80' : '#ffffff80',
              backdropFilter: 'blur(11px)',
            }}>
              <Space
                direction={md ? 'horizontal' : 'vertical'}
                size={[16,16]}
                style={{
                  padding: '1rem',
                  flexDirection: md ? 'row' : 'column-reverse'
                }}
              >
                <Button
                  danger
                  type='dashed'
                  block
                  title={t('forms.btn.clear.questions', 'Clear all')}
                  icon={<ClearOutlined />}
                  onClick={clearList}
                  style={{whiteSpace:'break-spaces', minWidth: 40 }}
                >
                  { !md && t('buttons.add.clearAll', 'Clear all')}</Button>
                <Button
                  block
                  title={t('forms.btn.reset')}
                  icon={<RedoOutlined />}
                  onClick={handleReset}
                  type='dashed'
                  style={{whiteSpace:'break-spaces', minWidth: 40 }}
                >
                  { !md &&t('forms.btn.reset')}</Button>
                <Select
                  mode="multiple"
                  maxTagCount={3}
                  placeholder="Vali väljad"
                  value={dataSource.map(f => f.name)}
                  onChange={handleSelectedFields}
                  options={groupedFields}
                  style={{ width: '100%' }}
                />
              </Space>
            </div>

          </div>
        </SortableContext>
      </DndContext>
    </>
  );
};
