import {
  MenuOutlined,
  GlobalOutlined,
  LogoutOutlined,
  LoginOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Grid, Menu, Switch } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

import { Language, Theme } from '@AppRoot';

import { ActionTypes } from '@App/settings/reducers';
import { ApplicationState } from '@App/settings/StateManager';
import { createKeyPathMapping, newNav, userHasAccess } from '@App/settings/navigation';
import { MobileLogo } from '@App/components/MobileLogo';
import { useKeycloak } from '@App/settings/keycloak';


const drawerContentWrapperStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height:' 100%'
};

export const MobileDrawer: React.FC = () => {
  const { t } = useTranslation('nav');
  const { keycloak, userData} = useKeycloak();
  const { state, dispatch } = React.useContext(ApplicationState);
  const [ visible, setVisible ] = React.useState(false);
  const screens = Grid.useBreakpoint();
  const location = useLocation();
  const navigate = useNavigate();

  const paths = createKeyPathMapping();
  const pathKey = Object.entries(paths).find(p => p[1] === location.pathname)?.[0] ?? '';
  const activeMenuKeys = (pathKey.split('-').map((k,i) => i === 0 ? k : pathKey)) ?? [];
  // const activeMenuKeys = [newNav.find(item => item.path === location.pathname)?.key || ''];
  
  const handleNav = (e: any) => {
    navigate( paths[e.key] );
    setVisible(false);
  };
  
  const showDrawer = () => {
    setVisible(true);
  };
  
  const onClose = () => {
    setVisible(false);
  };
  
  const handleThemeToggle = () => {
    dispatch({
      type: ActionTypes.Change_theme,
      payload: {
        theme: state.theme === Theme.Light ? Theme.Dark : Theme.Light,
      },
    });
  };
  
  const handleLanguageToggle = () => {
    dispatch({
      type: ActionTypes.Change_language,
      payload: {
        language: state.language === Language.ET ? Language.EN : Language.ET
      },
    });
  };
  
  const handleLogInOut = () => {
    if (keycloak?.authenticated) {
      keycloak?.logout();
    } else {
      keycloak?.login();
    }
  };
  
  const formatNavItem = ({label, ...rest}: App.NavItem | App.NavGroup): ItemType=> {
    return { ...rest, label: t(label) }
  }
  
  const mainMenuItems = newNav
    .filter( 
      userHasAccess(state.session.permissions) ||
      userHasAccess(keycloak?.realmAccess?.roles)
    )
    .map((pageItem) => ('items' in pageItem || 'children' in pageItem)
      ? {
          type: 'submenu',
          ...pageItem,
          label: t(pageItem.label),
          children: pageItem.items
            .filter(
              userHasAccess(state.session.permissions) ||
              userHasAccess(keycloak?.realmAccess?.roles)
            )
            .map(formatNavItem)
        }
      : formatNavItem(pageItem)
    );
  
  const settingsMenuItems = [
    {
      key: 'settings-divider',
      type: 'divider'
    },
    {
      key: 'change-theme',
      // icon: state.theme,
      onClick:  handleThemeToggle,
      label: (
        <Switch
          checkedChildren="dark"
          unCheckedChildren="light"
          checked={state.theme === 'dark'}
        />
      )
    },
    {
      key: 'toggle-lang',
      icon: <GlobalOutlined style={{fontSize:'1.1rem'}} />,
      onClick: handleLanguageToggle,
      title: 'Current language ' + state.language,
      label: state.language === Language.ET ? 'EST' : 'ENG'
    },
    {
      key: 'log-in-out',
      icon: keycloak?.authenticated ? <LogoutOutlined style={{fontSize:'1.1rem'}} /> : <LoginOutlined style={{fontSize:'1.2rem'}} />,
      onClick: handleLogInOut,
      label: keycloak?.authenticated ? t('buttons.logout', {username: userData?.username} ) : t('buttons.login'),
    },
  ];

  return (
    <div style={{ display: screens.xs ? 'block' : 'none' }}>
      <Button
        type="link"
        icon={<MenuOutlined />}
        title={t('links.label.menu')}
        onClick={showDrawer}
      />
      <Drawer
        title={t('links.label.menu')}
        placement="left"
        open={visible}
        onClose={onClose}
        styles={{
          body:{ padding: 0 }
        }}
      >
        <div style={drawerContentWrapperStyles}>
          {/* Application main menu for navigating */}
          <Menu
            mode="inline"
            theme={state.theme !== Theme.Auto ? state.theme : Theme.Dark}
            items={mainMenuItems}
            onClick={handleNav}
            selectedKeys={activeMenuKeys}
          />

          {/* Secondary menu for applications settings */}
          <Menu
            mode="inline"
            theme={state.theme !== Theme.Auto ? state.theme : Theme.Dark}
            items={settingsMenuItems}
            // onClick={handleNav}
            selectedKeys={[]}
          />

          {/* Logo at the bottom */}
          <div style={{ marginTop: 'auto', textAlign: 'center' }}>
            <MobileLogo style={{
              margin: '1rem 0',
              color: state.theme === 'dark' ? '#fff' : '#2C5696',
              maxHeight: '60%',
              maxWidth: '60%'
            }} />
          </div>
        </div>

      </Drawer>
    </div>
  );
};
