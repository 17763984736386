// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-pagination {
  margin-top: 0 !important;
  padding: 0.6rem 1rem;
  z-index: 2;
}
.ant-table-sticky-scroll .ant-table-sticky-scroll-bar {
  height: 0.6rem;
}
.table-row-size {
  opacity: 0.67;
}
.table-row-size:focus,
.table-row-size:hover {
  opacity: 0.87;
}
.table-row-size.isActive {
  font-weight: 700;
  opacity: 1;
}
.ak-table .ant-table-tbody tr.compact-row {
  height: 30px;
  line-Height: 30px;
}
.ak-table .ant-table-tbody tr.compact-row td {
  height: 30px;
  line-Height: 30px;
}
.ak-table .ant-table-tbody tr:not(.compact-row) td .ant-space {
  flex-wrap: wrap;
}
.ak-table .ant-table-tbody tr .ant-table-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.custom-columns-sort.isActive {
  border-left: 2px solid #1677ff;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.table-pagination {
  position: sticky;
  bottom: 0;
  margin-top: 1rem;
  padding: 0.6rem 0.8rem 0.8rem;
  border-top: 1px solid;
  border-radius: 0 0 1rem 1rem;
  -webkit-backdrop-filter: blur(11px);
  backdrop-filter: blur(11px);
}
.ak-table .ant-table-thead .ant-table-cell {
  padding: 6px 1rem 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/table.less"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,oBAAA;EACA,UAAA;AACF;AAEA;EAGI,cAAA;AAFJ;AAMA;EACE,aAAA;AAJF;AAKE;;EAEE,aAAA;AAHJ;AAKE;EACE,gBAAA;EACA,UAAA;AAHJ;AASI;EACE,YAAA;EACA,iBAAA;AAPN;AAQM;EACE,YAAA;EACA,iBAAA;AANR;AASI;EACE,eAAA;AAPN;AAJA;EAcM,mBAAA;EACA,uBAAA;EACA,gBAAA;AAPN;AAYE;EACE,8BAAA;AAVJ;AAaA;EACE,0BAAA;EACA,2BAAA;AAXF;AAaA;EACE,gBAAA;EACA,SAAA;EACA,gBAAA;EACA,6BAAA;EACA,qBAAA;EACA,4BAAA;EACA,mCAAA;EACA,2BAAA;AAXF;AAaA;EACE,wBAAA;AAXF","sourcesContent":[".table-pagination {\n  margin-top: 0 !important;\n  padding: .6rem 1rem;\n  z-index: 2;\n}\n\n.ant-table-sticky-scroll {\n  // opacity: .8;\n  .ant-table-sticky-scroll-bar {\n    height: .6rem;\n  }\n}\n\n.table-row-size {\n  opacity: .67;\n  &:focus,\n  &:hover {\n    opacity: .87;\n  }\n  &.isActive {\n    font-weight: 700;\n    opacity: 1;\n  }\n}\n\n.ak-table {\n  .ant-table-tbody tr {\n    &.compact-row {\n      height: 30px;\n      line-Height: 30px;\n      & td {\n        height: 30px;\n        line-Height: 30px;\n      }\n    }\n    &:not(.compact-row) td .ant-space {\n      flex-wrap: wrap;\n    }\n    .ant-table-cell {\n      white-space: nowrap;\n      text-overflow: ellipsis;\n      overflow: hidden;\n    }\n  }\n}\n.custom-columns-sort {\n  &.isActive {\n    border-left: 2px solid #1677ff;\n  }\n}\n.px-0 {\n  padding-left: 0 !important;\n  padding-right: 0 !important;\n}\n.table-pagination {\n  position: sticky;\n  bottom: 0;\n  margin-top: 1rem;\n  padding: .6rem .8rem .8rem;\n  border-top: 1px solid;\n  border-radius: 0 0 1rem 1rem;\n  -webkit-backdrop-filter: blur(11px);\n  backdrop-filter: blur(11px);\n}\n.ak-table .ant-table-thead .ant-table-cell {\n  padding: 6px 1rem .5rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
