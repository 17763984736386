import React from 'react';
import { Button, Form, Input, Select, Space, FormProps } from 'antd';
import type { FormInstance } from 'antd/es/form';

import { AddFields } from '../fields/AddFields';
import DeleteButton from '../buttons/DeleteButton';
import AddOptions from '../fields/AddOption';


const typeOptions = [
  { value: 'text', label: 'Regular text' },
  { value: 'textarea', label: 'Big textarea' },
  { value: 'select', label: 'Select' },
  { value: 'multi-select', label: 'Multi Select' },
  { value: 'radio', label: 'Radio (choose only one of)' },
  { value: 'checkbox', label: 'Checkbox (choose one or more)' },
  { value: 'email', label: 'Email(s)' },
  { value: 'number', label: 'Number' },
];

// field values for displaying extra options field
const optionsFields = ['select', 'multi-select', 'checkbox', 'radio'];
// fields that require at least one value
const oneOrMore = [ 'select', 'multi-select', 'checkbox' ];
// fields that require at least TWO value
const twoOrMore = [ 'radio' ];

// Regular expression to match a slug-like value
const slugRegex = /^[a-zA-Z0-9_-]+$/;

interface FieldFormProps {
  callback?: (values: any) => void;
  // onValuesChange: (name: string, value: any) => void;
}

export const FieldForm: React.FC<FieldFormProps & FormProps> = (props) => {
  const [ fieldType, setFieldType ] = React.useState<string>('');
  
  const onFinish = (values: any) => {
    submitForm(values);
  };

  const submitForm = (values: any) => {
    const { name, label, type, options, error_text, helper_text } = values;
    const payload = {
      name,
      label,
      type,
      settings: {
        options
      },
      error_text,
      helper_text,
    };

    if (props.callback) {
      props.callback( payload );
    }
  };
  
  
  const handleSelectChange = (value: string, option: any ) => {
    setFieldType(value);
    props.form?.setFieldValue('options', value);
  };

  const handleDelete = () => {
    console.log('Delete has been requested!')
  };

  const onFieldTypeChange = (value: string) => {
    switch (value) {
      case 'male':
        props.form!.setFieldsValue({ note: 'Hi, man!' });
        break;
      case 'female':
        props.form!.setFieldsValue({ note: 'Hi, lady!' });
        break;
      case 'other':
        props.form!.setFieldsValue({ note: 'Hi there!' });
        break;
      default:
    }
  }

  // Custom validator function to check if the input matches the slug regex
  const validateSlug = (_: unknown, value: string) => {
    if (!value || slugRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('No spaces allowed. Please enter a valid slug-like value.');
  };

  const listRenderValidator = (item:string) => async (_: unknown, names: string) => {
    const minOptionsCount = twoOrMore.includes(item) ? 2
      : oneOrMore.includes(item) ? 1 : 0;
    
    if (!names || names.length < minOptionsCount) {
      return Promise.reject(new Error(`At least ${minOptionsCount} option is required`));
    }
  }
  
  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      // ref={formRef}
      name="field-form"
      onFinish={onFinish}
      style={{ maxWidth: 600 }}
      {...props}
    >

      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true }, { validator: validateSlug }]}
      >
        <Input placeholder='Uniq question name. NOT displayed to user.' />
      </Form.Item>

      <Form.Item
        name="label"
        label="Label"
        rules={[{ required: true }]}
      >
        <Input placeholder='Question label, text displayed to user.' />
      </Form.Item>

      <Form.Item
        name="type"
        label="Type"
        rules={[{ required: true }]}
      >
        <Select
          placeholder="Select a question field type."
          onChange={handleSelectChange}
          allowClear
        >
          {
            typeOptions.map( (opt, i) => (
              <Select.Option key={i} value={opt.value}>
                {opt.label}
              </Select.Option>
            ))
          }
        </Select>
      </Form.Item>
      
      <Form.Item
        noStyle
        label="Options"
        labelCol={{ span: 4 }}
        shouldUpdate={(prev, current) => prev.type !== current.type}
        style={{ marginLeft:'16.6%'}}
      >
        {({ getFieldValue }) =>
          optionsFields.includes(getFieldValue('type'))
            ? (
              <AddOptions
                name={['settings', 'options']}
                forField={fieldType}
                rules={[{ validator: listRenderValidator(fieldType) }]}
              />
            )
            : null
        }
      </Form.Item>

      <Form.Item name={['settings', 'placeholder']} label="Placeholder" rules={[{ required: false }]}>
        <Input />
      </Form.Item>

      <Form.Item name={['settings', 'helper_text']} label="Helper Text" rules={[{ required: false }]}>
        <Input />
      </Form.Item>

      <Form.Item name={['settings','error_text']} label="Error Text" rules={[{ required: false }]}>
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Space>

          <Button type="primary" htmlType="submit">
            { props.form?.getFieldValue('id') ? 'Update' : 'Create' }
          </Button>

          <Button htmlType="button" onClick={() => props.form?.resetFields()}>
            Reset
          </Button>
          
          { props.form?.getFieldValue('id') && (
              <DeleteButton
                item='field'
                path='field'
                onDelete={handleDelete}
                record={{ id: props.form?.getFieldValue('id') }}
              />
          )}
        </Space>
      </Form.Item>

    </Form>
  );
}
