import {
  AppstoreOutlined,
  BarsOutlined,
  PlusOutlined,
  ReloadOutlined,
  UserOutlined,
  KeyOutlined,
  MoreOutlined,
  NumberOutlined
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Divider,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  notification,
  Input,
  Dropdown,
  Menu,
  Modal,
  Form,
  Row,
  Col,
  Grid,
  message
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { ApplicationState } from '@App/settings/StateManager';
import { ActionTypes } from '@App/settings/reducers';
import { handler } from '@App/settings/ApiHandler';
import { KC, useKeycloak } from '@App/settings/keycloak';
import { PermissionForm } from '@App/components/forms/PermissionForm';
import AppTable from '@App/components/tables/TableComponent';
import DeleteButton from '@App/components/buttons/DeleteButton';
import EditButton from '@App/components/buttons/EditButton';

import { DataContext } from '../../AccessView';

const tableName = 'permissions';


type PermissionsResponse = Responses.Default & {
  permissions: Responses.PermissionType[]
};

const getAllPermissions = (kc:KC) => handler<PermissionsResponse>({
  method: 'GET', path: '/v1/manage/permissions',
}, kc);

const addNewPermission = (kc:KC, payload: API.PayloadType) => handler<PermissionsResponse>({
  method: 'POST', path: '/v1/manage/permission/', payload
}, kc);

const updatePermission = (kc:KC, payload: API.PayloadType, id: number) => handler<PermissionsResponse>({
  method: 'PUT', path: `/v1/manage/permission/${id}`, payload
}, kc);



export const PermissionsList: React.FC = () => {
  const {state, dispatch} = React.useContext(ApplicationState);
  const { permissions, isLoading, setLoading, doRefresh } = React.useContext(DataContext);
  // const [api, contextHolder] = notification.useNotification();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const nav = useNavigate();
  
  const [search, setSearch] = React.useState<string>('');
 
  const [filteredPermissions, setFilteredPermissions] = React.useState<API.Permission[]>([]);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [action, setAction] = React.useState<'new'|'update'>('new');

  // const [permissions, setPermissions] = React.useState<PermissionGroupType[]>([]);

  const [form] = Form.useForm();
  const {lg, md} = Grid.useBreakpoint();

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setSearch(e.target.value);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handlePost = () => {
    // setIsLoading(true);
    const payload = form.getFieldsValue();
    // console.log('send payload', payload);
    const values = form.validateFields();
   
    // data.post(
    //   '/v2/manage/user/' + action,
    //   { action, ...payload }
    //   ).then((res) => {
    //     setModalOpen(false);
    //     doRefresh()
        
    //   })
    form.validateFields().then( (values) => {

    })

  };

  const handleCancel = () => {
    setModalOpen(false);
    // selectedUser.current = undefined;
  };

  const handleNewPermission = () => {
    form.resetFields();
    setAction('new')
    setModalOpen(true);
  };
  
  const handlePermissionUpdate = (id: string|number) => {
    form.resetFields();

    const foundValue = permissions.find(u => u.id == id);
    if (foundValue) {
      form.setFieldsValue(foundValue);
      setAction('update')
      setModalOpen(true);
    } else {
      message.warning('Ei leidnud soovitud õigust');
    }
  };

  React.useEffect(() => {
    if (permissions) {
      const newFilteredList = permissions.filter((p) =>
        p.name && p.name.toLowerCase().includes(search.toLowerCase()) ||
        p.label.toLowerCase().includes(search.toLowerCase()) ||
        p.role.toLowerCase().includes(search.toLowerCase())
      )
      
      newFilteredList.length > 0 && setFilteredPermissions(newFilteredList);
    }
  }, [search, permissions]);

  const columns: ColumnsType<API.Permission> = [
    {
      key: 'key',
      title: <NumberOutlined />,
      align: 'center',
      width: 48,
      // sorter: (a, b) => a.,
      render: (_, record, index: number) => (
        <Avatar size='small'>{index+1}</Avatar>
      ),
    },
    {
      key: 'name',
      title: t('table.column.label.permissionName'),
      dataIndex: 'name',
      width: 'auto',
      render: (_, record) => (
        <Space direction='vertical' size='small'>
          <Typography.Text strong>{record.label}</Typography.Text>
          <Typography.Text type='secondary'>{record.description ?? record.name}</Typography.Text>
        </Space>
      ),
    },
    {
      key: 'role',
      title: t('table.column.label.permissionRole'),
      dataIndex: 'role',
      responsive: ['sm'],
      render: (role) => <Tag color={'processing'}>{role}</Tag>
      // width: 'auto',
    },
    {
      key: 'action',
      title:  t('table.column.label.action'),
      align:'center',
      width: md ? 250 : 100,
      render: (_: string, record) => (
        <Space size='small'>
          <EditButton
            type='text'
            shape='round'
            record={record} 
            iconOnly={lg}
            text={t('forms.btn.update')}
            onClick={() => handlePermissionUpdate(record.id)}
          />
          <DeleteButton
            type='text'
            shape='round'
            iconOnly={false}
            item='permission'
            path={`/v1/manage/permission/${record.id}`}
            record={record}
          />
        </Space>
      ),
    },
  ];
  
  const loadTableConfig = () => {
    dispatch({
      type: ActionTypes.Load_table,
      payload: { tableName }
    });
  };

  React.useEffect(() => {
    if (!state.tables[tableName]) {
      loadTableConfig();
    };
  }, []);

  return (
    <>
      <Row justify='space-between' style={{ margin: '1rem 0' }}>
        <Col>
          <Input.Search
            placeholder={t('table.texts.placeholders.searchPermission')}
            onSearch={handleSearch}
            onChange={handleSearchChange}
          />
        </Col>
        <Col>
          <Button
            shape='round'
            key={"refresh"}
            icon={<ReloadOutlined/>}
            onClick={doRefresh}
          >
            { md && t('buttons.refresh')}
          </Button>
        </Col>
      </Row>

      <AppTable<API.Permission & {status?: string}>
        rowKey='id'
        tableName={tableName}
        tableTitle='pages.title.permissionsList'
        loading={isLoading}
        isAdmin={false}
        isSelectable={false}
        extraColumns={columns}
        dataSource={filteredPermissions}
        counter={permissions.length}
        sticky={{ offsetHeader: 64 }}
        scroll={{ x: 300 }}
        headerButtons={[
          <Button
            key='add-new-permission'
            shape='round'
            type='primary'
            icon={<PlusOutlined />}
            onClick={handleNewPermission}
          >
            {t('forms.btn.add')}
          </Button>
        ]}
      />
      
      <Modal
        title={
          <Typography.Title level={3} color='primary'>
            {action === 'new' ? 'Create new permission' : 'Update permission'}
          </Typography.Title>
        }
        open={modalOpen}
        onOk={handlePost}
        okText={t(`forms.btn.${action}`)}
        // okText={action === 'new' ? 'Create' : 'Update'}
        confirmLoading={isLoading}
        onCancel={handleCancel}
        // width={800}
      >
        <PermissionForm
          form={form}
          action={action}
        />
      </Modal>

    </>
  );
};
