import { Button, Divider, Space, Table, Tag, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';

// import { ApplicationState } from '../settings/StateManager';

interface ProjectType {
  key: string;
  name: string;
  title: string;
  status: string;
  description?: string;
}

const onDelete = (record: ProjectType) => {
  console.log('Deleting record:', record);
  // Add delete logic here
};


const columns: ColumnsType<ProjectType> = [
  {
    title: 'ID',
    dataIndex: 'key',
    key: 'key',
    width: 50,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (status) => {
      const color =
        status === 'draft'
          ? 'gold'
          : status === 'active'
          ? 'green'
          : status === 'deleted'
          ? 'red'
          : 'cyan';
      return <Tag color={color}>{status.toUpperCase()}</Tag>;
    },
    width: 100,
  },
];

const mockData: ProjectType[] = [
  {
    key: '1',
    name: 'form1',
    title: 'First Form',
    status: 'draft',
  },
  {
    key: '2',
    name: 'form2',
    title: 'Second Form',
    status: 'active',
  },
  {
    key: '3',
    name: 'form3',
    title: 'Third Form',
    status: 'inactive',
  },
  {
    key: '4',
    name: 'form 4',
    title: 'Forth Form',
    status: 'deleted',
  },
];

export const Project: React.FC = () => {
  const [projects, setProjects] = React.useState<ProjectType[]>(mockData);

  return (
    <div>
      <Typography.Title level={3}>new or edit project</Typography.Title>
      <Typography.Paragraph>
        new project form or select or edit existing project
        <br />
        TODO: Add a project form here that is related to active form.
      </Typography.Paragraph>

      <Divider />

      <Typography.Title level={3}>Project history</Typography.Title>
      <Table rowKey={'key'} columns={columns} dataSource={projects} />
    </div>
  );
};
