import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApiBackend, { HttpBackendOptions }from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import React from "react";

import config from './config';


let missingKeys: string[] = [];
// const CACHE_DURATION = 1000 * 60 * 60 * 24; // Cache for 24 hours
const CACHE_DURATION = 1000 * 60 * 2; // Cache for 2 minutes

// A function to save translations to localStorage
const saveToLocalStorage = (language: string, data: string) => {
  const cacheData = {
    translations: data,
    timestamp: Date.now()
  };
  localStorage.setItem(`translations_${language}`, JSON.stringify(cacheData));
  // localStorage.setItem(`translations_${language}`, data);
};

// A function to load translations from localStorage
const loadFromLocalStorage = (language: string): string | null => {
  // return localStorage.getItem(`translations_${language}`);
  const cached = localStorage.getItem(`translations_${language}`);
  if (!cached) return null;

  const { translations, timestamp } = JSON.parse(cached);

  if (Date.now() - timestamp > CACHE_DURATION) {
    // The cache has expired
    localStorage.removeItem(`translations_${language}`);
    return null;
  }

  return translations;
};

// A function to load translations from backend
const translationFetcher: HttpBackendOptions['request'] = async (
  options, url, data, callback
) => {

  const language = url.split('/').pop() ?? 'et';
  let translations = loadFromLocalStorage(language);
  
  if (translations) {
    let parsedTranslations;// = JSON.parse(translations);
    try {
      parsedTranslations = JSON.parse(translations);
  } catch (error) {
      console.error("Failed to parse translation data!");
      throw error;
  }
    callback(null, { status: 200, data: parsedTranslations });
    return;
  }

  try {
    const response = await fetch(url, options as RequestInit);
    translations = await response.text();

    if (response.status >= 500 && response.status < 600) throw new Error("Failed to connect");

    if (response.status === 200) {
      saveToLocalStorage(language, translations);
    }

    callback(null, { status: response.status, data: translations});
  } catch (err) {
    callback(err, { status: 500, data:{} });
  }
};


const initializeI18n = (): { read: () => void } => {
  let status = 'pending';
  let error: any;
  
  const promise = i18n
    .use(initReactI18next)
    .use(HttpApiBackend)
    .use(LanguageDetector)
    .init<HttpBackendOptions>({
      missingKeyHandler: (lngs: Array<string>, ns: string, key: string, fallbackValue: string) => {
        // console.warn(`Missing translation: ${key}`);
        missingKeys.push(key);
      },
      backend: {
        loadPath: `${config.backendUrl}/api/v3/translations/{{lng}}`,
        request: translationFetcher
      },
      fallbackLng: "et",
      interpolation: {
        escapeValue: false
      },
      detection: {
        order: ['queryString', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        caches: ['localStorage', 'cookie'],
        debug: config.env !== "production"
      },
      // saveMissing: true,
    })
    .then(() => {
      status = 'success';
    })
    .catch((err) => {
      status = 'error';
      error = err;
    });

    return {
      read() {
        if (status === 'pending') {
          throw promise;
        } else if (status === 'error') {
          throw error;
        } 
        // if status is 'success', it simply returns without throwing, indicating it's ready
      }
    };
  }

const i18nResource = initializeI18n();

export { missingKeys, i18nResource };

export default i18n;