import React from 'react';

import { InitialState } from '../initialState';

import { FilterActions } from './filterReducer';
import { FormActions, formReducer } from './formReducer';
import { LanguageActions, languageReducer } from './languageReducer';
import { ThemeActions, themeReducer } from './themeReducer';
import { SessionActions, sessionReducer } from './sessionReducer';
import { TableActions, tableReducer} from './tableReducer';
import { SidebarActions, sidebarReducer } from './sidebarReducer';
import { CounterActions, counterReducer } from './counterReducer';

// Below are defined all state action types that are available
// if new action is about to be added, here is where to start defining it.
export enum ActionTypes {
  Toggle_theme = 'TOGGLE_THEME',
  Change_theme = 'CHANGE_THEME',
  Change_filter = 'CHANGE_FILTER',
  Change_language = 'CHANGE_LANGUAGE',
  Toggle_language = 'TOGGLE_LANGUAGE', 
  Set_sidebar = 'SET_SIDEBAR', 
  Toggle_sidebar = 'TOGGLE_SIDEBAR', 
  Update_form = 'UPDATE_FORM',
  Update_table = 'UPDATE_TABLE',
  Update_table_value = 'UPDATE_TABLE_VALUE',
  SetHiddenCols = 'SET_HIDDEN_COLUMNS',
  Load_table = 'LOAD_TABLE',
  Update_user = 'UPDATE_USER',
  Logout = 'LOGOUT',
  Update_counts = 'UPDATE_COUNTERS',
}

export type AllActions =
  | ThemeActions
  | FilterActions
  | LanguageActions
  | FormActions
  | SessionActions
  | TableActions
  | SidebarActions
  | CounterActions;

export const mainReducer: React.Reducer<InitialState, AllActions> = (
  { language, theme, form, session, tables, isSidebarCollapsed, dataCounts },
  action
) => ({
  language: languageReducer(language, action),
  theme: themeReducer(theme, action),
  form,//: formReducer(form, action),
  session: sessionReducer(session, action),
  tables: tableReducer(tables, action),
  isSidebarCollapsed: sidebarReducer(isSidebarCollapsed, action),
  dataCounts: counterReducer(dataCounts, action)
});
