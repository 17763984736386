import { RightOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';
import type { TFunction } from 'i18next';


type AppLinkButtonProps = ButtonProps & {
  goTo: string;
  label: string;
  translation?: string;
  transKey?: string | TFunction;
  children?: React.ReactNode | string | number;
};

const AppLinkButton: React.FC<AppLinkButtonProps> = ({
  goTo, children, translation, label, ...props
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate(goTo);
  };

  const getContent = () => {
    if ('transKey' in props && translation) {
      // Use label as a fallback text
      return t(translation, label);
    }
    return label;
  };

  return (
    <Button
      onClick={handleClick}
      // style={{whiteSpace:'break-spaces'}}
      {...props}
      className={`app-link-button ${props.className ?? ''}`}
    >
      <span className='app-link-label'>
        {getContent()}
      </span>
      <span className='app-link-meta'>
        {children || <RightOutlined className="arrow-icon" />}
      </span>
    </Button>
  );
}

export default AppLinkButton;