import React, { FC, useState } from 'react';
import { Button, Form, Input, Select, Space, FormProps, Switch, Descriptions, Flex } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/es/select';
import { SwitchChangeEventHandler } from 'antd/es/switch';

import DeleteButton from '../buttons/DeleteButton';
import { useTranslation } from 'react-i18next';

// Regular expression to match a slug-like value
const slugRegex = /^[a-zA-Z0-9_-]+$/;

interface AdminDataUpdateProps {
  formOptions: Form.FormType[];
  isResearchForm: boolean;
  initialValues?: Responses.TTRData | Responses.ResearchData;
  callback?: (values: any) => void;
}

export const AdminDataUpdate: FC<AdminDataUpdateProps> = ({
  isResearchForm = false,
  ...props
}) => {
  const [ editableUID, setEditableUID ] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const formOptions = props.formOptions.map(i => ({value: i.id, label: i.title })) ?? [];

  const onFinish = (values: any) => {
    const { name, label, type, options, error_text, helper_text } = values;
    const payload = {
      ...values,
    };

    if (props.callback) {
      props.callback( payload );
    }
  };

  const handleDelete = () => {
    console.log('Delete has been requested!')
  };

  const handleFieldEditing: SwitchChangeEventHandler= (checked, e) => {
    setEditableUID(checked);
  }

  // Custom validator function to check if the input matches the slug regex
  const validateSlug = (_: unknown, value: string) => {
    if (!value || slugRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('No spaces allowed. Please enter a valid slug-like value.');
  };

  const statusOptions: Array<BaseOptionType | DefaultOptionType> = [
    { value: 'confirmed', label: t('settings.status.confirmed') },
    { value: 'draft', label: t('settings.status.draft') },
    { value: 'pending', label: t('settings.status.pending') },
    { value: 'active', label: t('settings.status.active') },
    { value: 'inactive', label: t('settings.status.inactive') },
    { value: 'deleted', label: t('settings.status.deleted') },
  ];

  return (
    <Form
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 22, offset: 1 }}
      // ref={formRef}
      name="field-form"
      onFinish={onFinish}
      style={{ maxWidth: 600, margin: 'auto' }}
      form={form}
      {...props}
    >
      <Form.Item
        name="uuid"
        label="UID"
        rules={[{ required: true }]}
      >
        <Input
          readOnly
          placeholder='A unique entity identifier.'
          showCount
          maxLength={36}
          disabled
        />
      </Form.Item>

      {
        isResearchForm && (
          <Form.Item
            name="grandikeskus_uid"
            label="Grandikeskus uid"
            rules={[{ required: false }]}
          >
            <Space
              direction='horizontal'
              wrap={false}
              align='center'
              className='flex-row first-item-100'
            >
              <Input
                placeholder='A unique entity identifier.'
                readOnly={!editableUID}
                disabled={!editableUID}
              />
              <Switch
                checked={editableUID}
                onChange={handleFieldEditing}
                checkedChildren={t("forms.label.yes")}
                unCheckedChildren={t("forms.label.no")}
              />
            </Space>
          </Form.Item>
        )
      }

      <Form.Item
        name="status"
        label={t('forms.label.status')}
        rules={[{ required: true }]}
      >
        <Select
          placeholder="Select a status of the entity."
          options={statusOptions}
        />
      </Form.Item>

      <Form.Item
        name="form_uid"
        label={t('forms.label.formName')}
        rules={[{ required: true }]}
      >
        <Select
          placeholder="Select the entity filled form."
          options={formOptions}
        />
      </Form.Item>
      <Form.Item
        name="form_filled"
        label={t('forms.label.isFilled')}
        rules={[{ required: true }]}
      >
        <Switch
          checkedChildren={t("settings.status.filled")}
          unCheckedChildren={t("settings.status.unfilled")}
        />
      </Form.Item>

      {/* <Descriptions>
        <Descriptions.Item label={t('last edited')}>
          {

          }
        </Descriptions.Item>
        <Descriptions.Item label={t('last edited')}>

        </Descriptions.Item>
      </Descriptions> */}

      <Form.Item
        // wrapperCol={{ offset: 8, span: 16 }}
      >
        <Flex justify='end' gap='.5rem'>
          <Button
            htmlType='button'
            shape='round'
            onClick={() => props.callback}
          >
            {t('forms.btn.cancel')}
          </Button>
          <Button
            htmlType='submit'
            type='primary'
            shape='round'
          >
            {t('forms.btn.save')}
          </Button>
        </Flex>
      </Form.Item>

    </Form>
  );
}
