import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Space } from 'antd';
import { FormListProps } from 'antd/es/form/FormList';


interface AddOptionProps {
  name: string | number | (string | number)[] | FormListProps['name'];
  forField: string;
  rules?: FormListProps['rules'];
  addText?: string;
}

const AddOptions: React.FC<AddOptionProps> = ({forField, ...rest}) => (
  <Form.List {...rest} >
    {(fields, { add, remove }) => (
      <>
        {fields.map(({ key, name, ...restField }) => (
          <Col key={key} xs={{push:0,span:24}} sm={{push:4,span:20}}>
            <Space style={{ display: 'flex' }} align="baseline">
              <Form.Item
                rules={[{ required: false, message: 'Missing option value' }]}
                wrapperCol={{span: 24 }}
                {...restField}
                name={[name, 'value']}
                >
                <Input placeholder="Option value" />
              </Form.Item>
              <Form.Item
                wrapperCol={{ span: 24}}
                rules={[{ required: true, message: 'Missing option label' }]}
                {...restField}
                name={[name, 'label']}
                >
                <Input placeholder="Option label" />
              </Form.Item>
              <MinusCircleOutlined onClick={() => remove(name)}  />
            </Space>
          </Col>
        ))}

        <Form.Item wrapperCol={{
          sm:{ push: 8, span: 8 },
          xs:{ push: 4, span: 16 }
        }}>
          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
            {rest.addText ?? `Add ${forField} option`}
          </Button>
        </Form.Item>
      </>
    )}
  </Form.List>
);

export default AddOptions;

