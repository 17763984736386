const initTheme = (localStorage.getItem('theme') as App.Themes) ?? 'light';
const initLanguage = (localStorage.getItem('i18nextLng') as App.Languages) ?? 'et';
const initSidebarState = localStorage.getItem('isSidebarCollapsed');
const initTables = JSON.parse(localStorage.getItem('tables')! ?? '{}' ) as App.TableConfigs;

/**
 * Application initial state object
 */
 export interface InitialState {
  language: App.Languages;
  theme: App.Themes;
  form: App.FormConfigs;
  session: App.SessionProps;
  tables: App.TableConfigs;
  isSidebarCollapsed: boolean | null;
  dataCounts?: Record<string, number> | null;
}

export const initialState: InitialState = {
  isSidebarCollapsed: ['true', 'false'].includes(initSidebarState ?? '')
    ? (initSidebarState === 'true')
    : null,
  language: initLanguage,
  theme: initTheme,
  form: {} as App.FormConfigs,
  tables: initTables,
  dataCounts: null,
  session: {
    roles: [] as Array<string>,
    groups: [] as Array<string>,
    faculties: [] as Array<API.FacultyGroupType|API.FacultyItem>,
    permissions: [] as Array<string>,
  }
};
