// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Rubik.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Rubik-Italic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Rubik-MonoOne.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/Lora.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/Lora-Italic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../fonts/Montserrat.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../fonts/Montserrat-Italic.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Rubik';
  font-style: normal;
  src: local('Rubik'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}
@font-face {
  font-family: 'RubikItalic';
  font-style: italic;
  src: local('RubikItalic'), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}
@font-face {
  font-family: 'RubikBold';
  font-style: normal;
  src: local('RubikBold'), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
}
@font-face {
  font-family: 'Lora';
  font-style: normal;
  src: local('Lora'), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
}
@font-face {
  font-family: 'LoraItalic';
  font-style: italic;
  src: local('LoraItalic'), url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  src: local('Montserrat'), url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype');
}
@font-face {
  font-family: 'MontserratItalic';
  font-style: italic;
  src: local('MontserratItalic'), url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('truetype');
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/fonts.css"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,kBAAA;EACA,+EAAA;AACF;AAEA;EACE,0BAAA;EACA,kBAAA;EACA,qFAAA;AAAF;AAGA;EACE,wBAAA;EACA,kBAAA;EACA,mFAAA;AADF;AAIA;EACE,mBAAA;EACA,kBAAA;EACA,8EAAA;AAFF;AAKA;EACE,yBAAA;EACA,kBAAA;EACA,oFAAA;AAHF;AAMA;EACE,yBAAA;EACA,kBAAA;EACA,oFAAA;AAJF;AAOA;EACE,+BAAA;EACA,kBAAA;EACA,0FAAA;AALF","sourcesContent":["@font-face {\n  font-family: 'Rubik';\n  font-style: normal;\n  src: local('Rubik'), url(../fonts/Rubik.ttf) format('truetype');\n}\n\n@font-face {\n  font-family: 'RubikItalic';\n  font-style: italic;\n  src: local('RubikItalic'), url(../fonts/Rubik-Italic.ttf) format('truetype');\n}\n\n@font-face {\n  font-family: 'RubikBold';\n  font-style: normal;\n  src: local('RubikBold'), url(../fonts/Rubik-MonoOne.ttf) format('truetype');\n}\n\n@font-face {\n  font-family: 'Lora';\n  font-style: normal;\n  src: local('Lora'), url(../fonts/Lora.ttf) format('truetype');\n}\n\n@font-face {\n  font-family: 'LoraItalic';\n  font-style: italic;\n  src: local('LoraItalic'), url(../fonts/Lora-Italic.ttf) format('truetype');\n}\n\n@font-face {\n  font-family: 'Montserrat';\n  font-style: normal;\n  src: local('Montserrat'), url(../fonts/Montserrat.ttf) format('truetype');\n}\n\n@font-face {\n  font-family: 'MontserratItalic';\n  font-style: italic;\n  src: local('MontserratItalic'), url(../fonts/Montserrat-Italic.ttf) format('truetype');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
