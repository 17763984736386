import {
  ToolOutlined, FileAddTwoTone,
} from '@ant-design/icons';
import {
  Button,
  Descriptions,
  Drawer,
  Grid,
  List,
  message,
  Skeleton,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';

import DeleteButton from '@App/components/buttons/DeleteButton';
import EditButton from '@App/components/buttons/EditButton';
// import { QuestionnaireBuilderForm } from '@App/components/forms/QuestionnaireBuilderForm';
import { DraggableTable } from '@App/components/tables/DraggableTable';
import { handler } from '@App/settings/ApiHandler';
import { DividerTitle } from '@App/components/texts/DividerTitle';
import { DataContext } from '../../AdminFormView';
import { KC, useKeycloak } from '@App/settings/keycloak';
import StatusDropdown from '@App/components/buttons/StatusButton';
import { useTranslation } from 'react-i18next';

type PayloadType = {
  [K: string]: object;
};

type FormsResponse = {
  forms: Form.Questionary[];
  fields: Form.FieldType[];
};

const getStatusColor = (s: string):string => {
  return s === 'draft'
    ? 'cyan' : s === 'active'
    ? 'green' : s === 'deleted'
    ? 'red' : 'gold'
}
type PostPayload = {
  form?: Form.Questionary|API.Form|Form.FormType;
  fields:Form.QuestionType[];
}
type PostRes = {
  success?: string;
  error?: string;
}
const postForm = (kc: KC, data: PostPayload ) => handler<PostRes>({
  method: 'PUT',
  path: '/v2/manage/forms/update/',
  payload: { action: 'update', ...data}
}, kc);

type StatusPostPayload = {
  uuid: Form.Questionary['uuid'];
  newStatus: string;
}
const updateFormStatus = (kc: KC, data: StatusPostPayload ) => handler<PostRes>({
  method: 'PUT', path: '/v2/manage/form/status/', payload: { action: 'status', ...data}
}, kc);

type SelectedFormFieldsType = Array<Form.QuestionType | Form.FieldType | API.Field | API.FieldOrder> | null;

type UpdateValues = {
  form: any;
  fieldValues: Form.QuestionType|API.FieldOrder;
}
export function FormBuilder() {
  const [isFormEditOpen, setIsFormEditOpen] = React.useState<boolean>(false);
  const { keycloak } = useKeycloak();
  const { t } = useTranslation(['forms', 'info', 'tables']);
  const {sm, md, lg} = Grid.useBreakpoint();
  const data = React.useContext(DataContext);

  const selectedFormRef = React.useRef<Form.Questionary|null>(null);
  const selectedForm = React.useRef<API.Form|Form.FormType>();
  const newFormValues = React.useRef<Form.QuestionType[]>();

  const showDrawer = (idOrName: number|string) => {
    const updateForm = data.forms.find((f) => {
      if (typeof(idOrName) === 'number'){
        return f.id === idOrName
      }
      else if (typeof(idOrName) === 'string'){

        return f.uuid === idOrName || f.name === idOrName
      }
      else return false;
    });
    if (updateForm) {
      selectedForm.current = updateForm;
    }
    setIsFormEditOpen(true);
  };

  const handleClose = () => {
    selectedFormRef.current = null;
    selectedForm.current = undefined;
    setIsFormEditOpen(false);
  };

  // const fetchForms = () =>
  //   getForms().then((data) => {
  //     // console.log(data)
  //     // data.forms && setForms(data.forms);
  //     data.forms && setForms(data.forms);
  //     data.fields && setFields(data.fields);
  //   });

  const updateQuestionnaire = (payload: Form.QuestionnaireType) => {
    console.log('Update Questionnaire!', payload);
    // props.onSubmit(values);
    // setUpdateValues(payload.fields);
  };

  const handleSave = () => {
    // console.log('start saving here, updateValues:',selectedFormRef, newFormValues);
    if (newFormValues.current) {
      const payload = {
        ...(selectedForm.current ?? {}),
        fields: newFormValues.current

      }
      postForm( keycloak!, payload).then((res) => {
        if (res.success) {
          message.success(t(`forms.success.${res.success}`, t('forms.success.default.update')))
          data.doRefresh()
          handleClose();
        }
      })

    }
  };

  const handleFormStatusChange = (uuid:string) => (newStatus:string) => {
    // console.log('start saving here, updateValues:',uuid, newStatus);
    updateFormStatus( keycloak!, { uuid, newStatus })
      .then((res) => {
        if (res.success) {
          data.doRefresh();
          message.success(t(`forms.success.${res.success}`, t('forms.success.status.update')))
        }
      });
  };

  const handleRefresh = () => data.onRefresh();

  const handleDelete = () => {
    console.log('delete something? ')
  }
  const columns: ColumnsType<Form.Questionary | Form.FormType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: ['name', 'title'],
      render: (_, record) => {
        return (
          <>
            <Typography.Text type='secondary'>{record.name}</Typography.Text>
            <Typography.Title level={4} style={{ marginTop: 0 }}>
              {record.title}{' '}
            </Typography.Title>
          </>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Questions',
      dataIndex: 'fields',
      key: 'fields',
      render: (_, record: Form.Questionary) => {
        return <> {record.fields && record.fields.length} </>;
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status) => {
        const color = status === 'draft'
          ? 'cyan'
          : status === 'active'
          ? 'green'
          : status === 'deleted'
          ? 'red'
          : 'gold';
        return <Tag color={color}>{status.toUpperCase()}</Tag>;
      },
      width: 100,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: string, record: Form.FormType | Form.Questionary) => (
        <Space size='small'>
          <Button
            // type='primary'
            icon={<ToolOutlined />}
            onClick={() => showDrawer(record.id)}
          />
          <EditButton record={record} />
          <DeleteButton
            item='form'
            path='forms'
            onDelete={handleDelete}
            record={record}
          />
        </Space>
      ),
      width: 100,
    },
  ];

  return (
    <div>
      <DividerTitle title={t('titles.section.forms')} />
      
      <List
          loading={data.isLoading}
          itemLayout="horizontal"
          dataSource={data.forms.sort((a,b)=> a.title.localeCompare(b.title))}
          
          renderItem={(item,idx) => (
            <List.Item
              actions={[
                <StatusDropdown
                  initialValue={item.status}
                  onChange={handleFormStatusChange(item.uuid)}
                  isLoading={data.isLoading}
                />,
                <Button
                  type='primary'
                  icon={<ToolOutlined />}
                  onClick={() => showDrawer(item.id ?? item.name)}
                />,
                <EditButton
                  type='dashed'
                  record={item}
                  goTo={'/manage/forms/builder/'+item.name}
                />,
                <DeleteButton
                  iconOnly
                  item='form'
                  type='dashed'
                  path='/v2/manage/form/'
                  onDelete={handleDelete}
                  record={item}
                />
              ]}
            >
              <Skeleton avatar title={false} loading={data.isLoading} active>
                <List.Item.Meta
                  avatar={<FileAddTwoTone />}
                  title={item.title}
                  description={item.description ?? item.name}
                />

                {/* <Tooltip
                  key={'question-'+idx}
                  title="Form current status"
                  color={getStatusColor(item.status ?? 'n/a')}
                >
                  <Tag color={getStatusColor(item.status?? 'n/a')}>
                    {item.status?.toUpperCase() ?? 'unknown'}
                  </Tag>
                </Tooltip> */}
                
                <Tooltip
                  key={'questions-amount-'+idx}
                  title="Form questions amount"
                  // color={item.fields.length > 0 ? 'green' : 'gold'}
                  // color={getStatusColor(item.status ?? 'n/a')}
                >
                  <Tag color={getStatusColor(item.status ?? 'n/a')}>
                    {item.fields && item.fields.length}
                  </Tag>
                </Tooltip>

              </Skeleton>
            </List.Item>
          )}
        />
      
      <Drawer
        title={t('forms.titles.update.form')}
        width={ lg ? '80vw' : md ? '90vw' : '99vw'}
        onClose={handleClose}
        open={isFormEditOpen}
        styles={{
          body: {
            paddingBottom: 80
          }
        }}
        extra={
          <Space size='large'>
            <Button
              onClick={handleClose}
              style={{ width: '150%', transform: 'translateX(-25%)' }}
            >
              {t('forms.btn.cancel')}
            </Button>
            <Button onClick={handleSave} type='primary'>
              {t('forms.btn.save')}
            </Button>
          </Space>
        }
      >
        {selectedForm.current && (
          <>
            <Descriptions
              title={t('forms.titles.update.formInfo')}
              layout='horizontal'
              style={{
                paddingLeft: '2.5rem',
                // backgroundColor: 'red'
              }}
            >
              <Descriptions.Item label={t('forms.label.formName')} >
                {selectedForm.current.name}
              </Descriptions.Item>
              <Descriptions.Item label={t('forms.label.formTitle')}>
                {selectedForm.current.title}
              </Descriptions.Item>
              <Descriptions.Item label={t('forms.label.formStatus')}>
                <Tag color={selectedForm.current.status === 'draft' ? 'gold'
                    : selectedForm.current.status === 'active' ? 'green'
                    : selectedForm.current.status === 'deleted' ? 'red' : 'cyan'}>
                  {selectedForm.current.status}
                </Tag>
              </Descriptions.Item>
            </Descriptions>
            
            <DividerTitle title={t('forms.titles.update.formFields')} />
            
            <DraggableTable
              // currentFormID={selectedForm.current.id}
              currentForm={selectedForm.current}
              allFields={data.fields}
              initialFields={selectedForm.current?.fields}
              onFieldsChange={(newFieldsList: Form.QuestionType[]) => {
                // console.log('onFieldsChange, new values', newFieldsList )
                // setUpdateValues( newFieldsList )
                console.log('onFieldsChange callback, ready to post?', newFieldsList);
                newFormValues.current = newFieldsList;
              }}
              // onUpdateOrder={(newOrder: Form.FieldType[]) => {
              //   console.log('Function not implemented.', newOrder);
              // }}
            />
          </>
        )}
      </Drawer>
    </div>
  );
}
