import {
  Button,
  ButtonProps, Col, Divider, Grid, message, Row, Space, Spin, Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import Keycloak from 'keycloak-js';
import React from 'react';

import { capitalize } from '@App/components/helpers';
import { handler } from '@App/settings/ApiHandler';
import { useKeycloak } from '@App/settings/keycloak';
import AppLinkButton from '@App/components/buttons/AppLinkButton';
import ContactInfo from '@App/components/ContactInfo';
import { ApplicationState, StateManager } from '@App/settings/StateManager';
import { hasAccess, permissions } from '@App/settings/navigation';
import { Hero } from '@App/components/Hero';
import { ActionTypes } from '@App/settings/reducers';


type CountItem = {
  name: string;
  count: number;
  icon?: React.ReactNode;
}

type CountItemsObj = {
  [name: string]: number;
}

type UrlItem = {
  key: string;
  name: string;
  path: string;
  disabled?: boolean;
  icon?: React.ReactNode;
}

type LinksType = {
  name: string,
  path: string,
  transKey?: string,
  disabled?: boolean,
  type?: ButtonProps['type'],
}
type PermissionBasedLinksType = LinksType & {
  access: string[],
  counter?: boolean
}

const appInternalLinks: UrlItem[] = [
  { key: "1", name: "Andmekaitse põhimõisted", path: "/kkk"},
  // { key: "2", name: "Loo projekti visand", path: "/projects/new"},
  { key: "3", name: "Tutvu materjalidega", path: "/materials", disabled: true},
  { key: "4", name: "Kontrolli oma teadmisi", path: "/test", disabled: true},
  { key: "5", name: "Teavita rikkumisest", path: "/report", disabled: true},
  { key: "6", name: "Korduma kippuvad küsimused", path: "/kkk"},
];

const ttrActions: Array<PermissionBasedLinksType> = [
  {
    name: 'TTR-add',
    transKey: 'links.TTRlist.add',
    path: '/forms/oppetoo',
    type: 'primary',
    access: permissions.ttr
  },
  {
    name: 'myFaculty',
    transKey: 'links.TTRlist',
    path: '/oppetoo/',
    access: permissions.ttr,
    counter: true
  }
];

const researchActions: Array<PermissionBasedLinksType> = [
  {
    name: 'newProject',
    transKey: 'links.researchProjects.add',
    path: '/forms/researchProject',
    type: 'primary',
    access: permissions.ut
  },
  {
    name: 'myProjects',
    transKey: 'links.researchProjects',
    path: '/researchProjects/',
    access: permissions.ut,
    counter: true
  },
];

const dataManagement: LinksType[] = [
  {
    name: 'allStudy',
    transKey: 'links.TTRlist',
    path: '/manage/ttr/oppetoo/',
    type: 'dashed',
  },
  {
    name: 'allProjects',
    transKey: 'links.researchProjects',
    path: '/manage/ttr/researchProjects/',
    type: 'dashed',
  },
];

const adminDataActions: LinksType[] = [
  // {
  //   name: 'allStudy',
  //   transKey: 'links.TTRlist',
  //   path: '/manage/ttr/oppetoo/',
  //   type: 'dashed',
  // },
  // {
  //   name: 'allProjects',
  //   transKey: 'links.researchProjects',
  //   path: '/manage/ttr/researchProjects/',
  //   type: 'dashed',
  // },
  {
    name: 'allTranslations',
    transKey: 'links.manage.translations',
    path: '/manage/translations',
    type: 'text',
  },
  {
    name: 'forms',
    transKey: 'links.manage.forms',
    path: '/manage/forms/builder',
    type: 'text',
  },
  {
    name: 'fields',
    transKey: 'links.manage.fields',
    path: '/manage/forms/fields',
    type: 'text',
  },
  // {
  //   name: 'new form',
  //   transKey: 'links.new.form',
  //   path: '/manage/forms/builder/new',
  //   type: 'text',
  // },
  // {
  //   name: 'new field',
  //   transKey: 'links.new.field',
  //   path: '/manage/forms/fields/new',
  //   type: 'text',
  // },
];

const adminToolsActions: LinksType[] = [
  {
    name: 'new user',
    transKey: 'forms.title.newUser',
    path: '/manage/access/users/new',
    type: 'text',
  },
  {
    name: 'users',
    transKey: 'links.manage.access',
    path: '/manage/access/',
    type: 'text',
  },
  // {
  //   name: 'users',
  //   transKey: 'links.manage.users',
  //   path: '/manage/access/users',
  //   type: 'text',
  // },
  {
    name: 'allFaculties',
    transKey: 'links.manage.faculties',
    path: '/manage/access/faculties',
    type: 'text',
  },
  // {
  //   name: 'groups',
  //   transKey: 'links.manage.groups',
  //   path: '/manage/access/groups',
  //   type: 'text',
  // },
  // {
  //   name: 'manage translations',
  //   transKey: 'links.manage.translations',
  //   path: '/manage/translations',
  //   type: 'text',
  // },
];

function mergeArrays(array1: LinksType[], array2: LinksType[]) {
  let mergedArray = [];
  let maxLength = Math.max(array1.length, array2.length);

  for (let i = 0; i < maxLength; i++) {
      if (i < array1.length) {
        mergedArray.push(array1[i]);
      }
      if (i < array2.length) {
        mergedArray.push(array2[i]);
      }
  }

  return mergedArray;
}

type MetaRes = {
  counts: Record<string, number> | null;// | CountItemsObj;
  totals?: CountItem[];
  userGroups?: API.GroupType[]
};

const getCounts = (kc: Keycloak) => handler<MetaRes>({
  path: '/v1/meta', method: 'GET'
}, kc);


export const LandingPage: React.FC = () => {
  const isMounted = React.useRef<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [counts, setCounts] = React.useState<CountItemsObj>({})
  const {keycloak } = useKeycloak();
  const { state, dispatch } = React.useContext<StateManager>(ApplicationState);
  const { t } = useTranslation();
  const { xs, sm, md } = Grid.useBreakpoint();

  const handleDataFetch = () => {
    const aC = new AbortController();
    setLoading(true);

    getCounts(keycloak!)
    .then( (data) => {
      setCounts(prev => ({ ...prev, ...data.counts}));
      if (data.counts) {
        dispatch({
          type: ActionTypes.Update_counts,
          payload: data.counts
        })
      }
    })
    .catch( () => {
      message.error('Failed to fetch meta data from backend.')
    })
    .finally(() => {
      setLoading(false);
    });
    
    return () => {
      aC.abort()
    }
  }  


  React.useEffect( () => {
    let cleanup = () => {};
    
    if ( !isMounted.current ) {
      isMounted.current = true;
      cleanup = handleDataFetch();
    }

    return () => {
      isMounted.current = false;
      cleanup();
    };
  }, [])


  return (
    <div style={{ maxWidth: 990, margin: 'auto', fontSize:16 }}>

      <Hero />

      <Spin spinning={loading}>

        <Row justify="center" align={'middle'} gutter={[14,14]} style={{ margin: '1rem 0'}}>
          <Col span={24}>
            <Divider>
              <Typography.Text type='secondary'>
                {t('settings.btn.instruction')}
              </Typography.Text>
            </Divider>
          </Col>
          {hasAccess(permissions.ttr, [ ...state.session.permissions, ...state.session.groups ]) && (
            <Col key='juhend-1' span={12} style={{ textAlign: "end" }}>
              <Button
                type={'dashed'}
                shape={'round'}
                onClick={() => window.open('/oppetoo_juhend.pdf', '_blank')}
                style={{ fontWeight: 600 }}
              >
                {t('settings.btn.facultyInstruction')}
              </Button>
            </Col>
          )}
          <Col key='juhend-2' span={12}>
            <Button
              type={'dashed'}
              shape={'round'}
              onClick={() => window.open('/teadusuuringute_juhend.pdf', '_blank')}
              style={{ fontWeight: 600 }}
            >
              {t('settings.btn.researchInstruction')}
            </Button>
          </Col>
          <Col span={24}>
            <Divider>
              <Typography.Text type='secondary'>
                {t('pages.title.userActions')}
              </Typography.Text>
            </Divider>
          </Col>

          {
            ttrActions.map(({path, name, transKey, counter, ...rest}, index) =>
              hasAccess(rest.access, [ ...state.session.permissions, ...state.session.groups ]) &&
              <Col
                key={`ttr-${index}`}
                order={index}
                xs={24}
                sm={20}
                md={12}
                className='app-big-buttons'
              >
                <AppLinkButton
                  block
                  key={`${name}-${index}`}
                  label={t(transKey ?? '', name)}
                  goTo={path}
                  shape='round'
                  children={counter ? counts?.[name] ?? counts['publicProjects'] : null}
                  {...rest}
                />
              </Col>
            )
          }
          {
            researchActions.map(({path, name, transKey, counter, ...rest}, index) =>
              // hasAccess(rest.access, [ ...state.session.permissions, ...state.session.groups ]) &&
              <Col
                key={`research-${index}`}
                order={md ? index : index + 2}
                xs={24}
                sm={20}
                md={12}
                className='app-big-buttons'
              >
                <AppLinkButton
                  block
                  label={t(transKey ?? '', name)}
                  goTo={path}
                  shape='round'
                  {...rest}
                  >
                    {
                      counter &&
                      counts?.['myProjects'] > 0 &&
                      counts?.['myProjects']
                    }
                    {
                      counter &&
                      counts?.['myProjects'] > 0 &&
                      counts?.['publicProjects'] > 0 && (
                        '/'
                      )
                    }
                    {
                      counter &&
                      counts?.['publicProjects'] > 0 &&
                      counts?.['publicProjects']
                    }
                    {
                      name == 'myProjects' &&
                      'mySharedProjects' in counts &&
                      counts?.['mySharedProjects'] > 0
                          ? `/${counts?.['mySharedProjects']}`
                          : ''
                    }

                </AppLinkButton>
              </Col>
            )
          }
 
        </Row>

        {
          state.session.permissions?.length > 0 &&
          hasAccess(permissions.admin, state.session.permissions) && (
          <Row justify="center" gutter={[20,20]} style={{ margin: '3rem 0 1rem'}}>
            <Col xs={24}  style={{marginBottom:10}}>
              <Divider>
                <Typography.Text type='secondary'>
                  {t('pages.section.adminDataActions')}
                </Typography.Text>
              </Divider>
            </Col>
            {
              dataManagement.map(({path, name, transKey, ...rest}, index) =>
                <Col
                  key={`${name}-${index}`}
                  xs={24}
                  sm={20}
                  md={12}
                  span={12}
                  className='app-big-buttons'
                  style={{marginBottom:10}}
                >
                  <AppLinkButton
                    block
                    label={t(transKey ?? '', name)}
                    goTo={path}
                    shape='round'
                    className='color-admin btn-border'
                    {...rest}
                  >
                    {
                      name == 'myProjects'
                        ? `${counts?.[name] || counts?.[capitalize(name)]}/${counts?.['mySharedProjects']}`
                        : counts?.[name] || counts?.[capitalize(name)]
                    }
                  </AppLinkButton>
                </Col>
              )
            }
          </Row>
        )}
        
        {
          state.session.permissions?.length > 0 &&
          hasAccess(permissions.admin, state.session.permissions) && (
          <Row
            justify={md ? 'start' : 'center'}
            gutter={[14,14]}
            style={{ margin: '3rem 0 1rem'}}
          >
            <Col span={24} >
              <Divider>
                <Typography.Text type='secondary'>
                {t('pages.section.adminAppActions')}
                </Typography.Text>
              </Divider>
            </Col>
            
            {
              (
                xs || md ? [...adminDataActions, ...adminToolsActions]
                : mergeArrays(adminDataActions, adminToolsActions)
              )
              .map(({path, name, transKey, ...rest}, index) =>
                <Col
                  key={`${name}-${index}`}
                  xs={24} sm={12} md={8} lg={8}
                  className='app-big-buttons'
                >
                <AppLinkButton
                  block
                  label={t(transKey ?? '', name)}
                  goTo={path}
                  shape='round'
                  {...rest}
                  style={{
                    // borderStyle: 'solid',
                    // borderWidth: 1,
                    borderColor: '#cccccc38'
                  }}
                >
                  {
                    counts &&
                    (name in counts || capitalize(name) in counts) &&
                    counts?.[name] || counts?.[capitalize(name)]
                  }
                </AppLinkButton>
                </Col>
              )
            }
          </Row>
        )}
      </Spin>

      <Row justify={'center'} style={{ margin: '3rem 0 1rem'}}>
        <Col span={24} >
          <Divider>
            <Typography.Text type='secondary'>
              {t('site.contact.name')} {t('site.label.contact')}
            </Typography.Text>
          </Divider>
        </Col>

        <Col xs={24} sm={20} md={16} >
          <ContactInfo />
        </Col>
      </Row>

    </div>
  );
}
