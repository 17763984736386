import { FloatButton, Layout } from 'antd';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { AppFooter } from '@App/components/footer';
import Header from "@App/components/header";
import { ApplicationState } from '@App/settings/StateManager';



export const LayoutWithSidebar: React.FC<{children?: React.ReactNode}> = ({children}) => {

  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Layout style={{ minHeight: '100vh' }}>

      <Layout hasSider className="site-layout">
        
        <Header.AppSidebar  />

        <Layout.Content className="site-page">         
          <Header.WhiteHeader>
            {
              location.pathname !== '/'
              && <Header.AppBreadcrumbs style={{ margin: '1rem 0' }} />
            }
          </Header.WhiteHeader>
    
          <div className='page-content'>
            <Outlet />
            {children}
          </div>
    
          <AppFooter />
        </Layout.Content>

      </Layout>
      
      <FloatButton.BackTop tooltip={t('settings.btn.backTop')} />
      

    </Layout>
  );
};
