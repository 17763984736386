import React from 'react';

import { InitialState, initialState } from './initialState';
import { AllActions, mainReducer } from './reducers';


/**
 * Application state manager
 * @param state current state value
 * @param dispatch a dispatch func for update state
 */
interface StateManager {
  state: InitialState;
  dispatch: React.Dispatch<AllActions>;
}

const ApplicationState = React.createContext<StateManager>({
  state: initialState,
  dispatch: () => null,
});

const StateManager: React.FC<{ children: React.ReactNode }> = (props) => {
  const [state, dispatch] = React.useReducer(mainReducer, initialState);

  return (
    <ApplicationState.Provider value={{ state, dispatch }}>
      {props.children}
    </ApplicationState.Provider>
  );
};

export { StateManager, ApplicationState };
