import { Layout, Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';


const pageStyles: React.CSSProperties = {
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
};

const wrapperStyles: React.CSSProperties  = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center'
};

const loadingIcon = <LoadingOutlined style={{fontSize: '3rem'}} spin />;

export const LoadingPage: React.FC = () => (
  <Layout style={pageStyles}>
    <Layout.Content style={wrapperStyles}>

      <Spin indicator={loadingIcon} />

      <Typography.Text
        strong
        type='secondary'
        style={{ marginTop: '1rem' }}
      >
        Loading ...
      </Typography.Text>

    </Layout.Content>
  </Layout>
);
