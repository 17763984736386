import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from "dayjs";
import React from "react";
import type { KeycloakProfile } from "keycloak-js";
import type { ColumnType, TableProps } from 'antd/es/table';

/**
 * Capitalize the first letter of a string
 * @param {string} str input string to be capitalized
 * @returns Capitalized string value
 */
export const capitalize = (str: string): string => {
  const firstLetter = str.charAt(0).toUpperCase();
  return firstLetter + str.slice(1);
}

type GetUsersFirstLettersProps = {
  username: string | KeycloakProfile['username'],
  firstName?: string | KeycloakProfile['firstName'],
  lastName?: string | KeycloakProfile['lastName']
};
export const getUsersFirstLetters = (userData?: KeycloakProfile | GetUsersFirstLettersProps): string => {
  if (!userData ) return '';

  let firstLetters = '';
  if (userData.firstName) {
    firstLetters += userData.firstName[0].toUpperCase();
  }
  if (userData.lastName) {
    firstLetters += userData.lastName[0].toUpperCase();
  }
  if (userData.username && firstLetters.length == 0) {
    return userData.username.toUpperCase();
  }

  return firstLetters;
};

/**
 * 
 * @param dateString 
 * @returns 
 */
export function formatDate(dateString: string | number | null | undefined): string {
  // Check if dateString is not valid at the very start
  if (!dateString || dateString === 'None') {
    // console.error(`Invalid input: ${dateString}`);
    return 'n/a';
  }

  try {
    const date = new Date(
      typeof(dateString) == 'string' ? dateString : dateString * 1000
    );

    if (isNaN(date.getTime())) {
      throw new Error(`Invalid date string: ${dateString}`);
    }

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    return date.toLocaleString('et-EE', options);
  } catch (error) {
    console.error(error);
    return 'n/a';
  }
};

dayjs.extend(customParseFormat); // Extend dayjs with the plugin

export const formatDateTime = (
  dateString: string | number | null,
  fromNow: boolean = false,
  format: string = 'DD.MM.YYYY - hh:mm'
) => {
  // Check if dateString is not valid at the very start
  if (!dateString || dateString === 'None') {
    // console.log('dateString', dateString, ' | fromNow', fromNow)
    return 'n/a';
  }

  try {
    let date;
    
    if (typeof dateString === 'string') {
      // Use dayjs to parse the date string with the specified format
      date = dayjs(dateString, 'DD-MM-YYYY HH:mm:ss');
      if (!date.isValid()) {
        throw new Error(`Invalid date string: ${dateString}`);
      }
    }
    else if (typeof dateString === 'number') {
      // Handle Unix timestamp (in seconds)
      date = dayjs.unix(dateString);
    }
    else {
      throw new Error(`Invalid date string: ${dateString}`);
    }
    
    // Check if the date is from today
    if (fromNow && date.isSame(dayjs(), 'day')) {
      // Return relative time if the date is from today
      return date.fromNow();
    }
    else {
      // Otherwise, return the formatted date
      // return date.format('MMMM D, YYYY h:mm A');
      return date.format(format);
    }
  } catch (error) {
    console.error(error);
    return 'n/a';
  }
}

/**
 * Retrieves nested data from an object based on a given dataIndex.
 * @param data - The data object from which to retrieve the value.
 * @param dataIndex - A string or array of strings representing the path to the desired data.
 * @returns - The value from the data object at the specified dataIndex.
 */
const getNestedData = (data: any, dataIndex: string | string[]) => {
  // If dataIndex is an array, iterate over the array to access nested data.
  if (Array.isArray(dataIndex)) {
    return dataIndex.reduce((acc, key) => acc[key], data);
  }
  // If dataIndex is a string, directly access the data.
  return data[dataIndex];
};

/**
 * Exports table data to a CSV file.
 * @param columns - The columns configuration of the AntD table, which includes titles and dataIndex.
 * @param dataSource - The data source for the AntD table, containing the actual table data.
 */
export const exportToCSV = (columns: TableProps<any>['columns'], dataSource: TableProps<any>['dataSource']): void => {
  // Define an array with the keys of columns that are date types
  const dateColumns = ['created_at', 'updated_at', 'updated']; // Replace with your actual date column keys
  // Check if columns or dataSource is not provided or empty
  if (!columns || !dataSource) {
    console.error('Invalid columns or dataSource');
    return;
  }

  const csvRows: string[] = [];

  // Filter out valid columns (those with a dataIndex and not column groups)
  const validColumns = columns.filter(
    (col): col is ColumnType<any> =>
      'dataIndex' in col && col.dataIndex != null
  );

  // Extract headers from valid columns and join them to form the header row of the CSV
  const headers = validColumns.map(col => col.title).filter(title => title != null);
  csvRows.push(headers.join(';'));

  // Iterate over each row of data
  dataSource.forEach(row => {
    const values = validColumns.map(col => {
      // Retrieve the cell value, handling nested dataIndex as necessary
      let cellValue = getNestedData(row, col.dataIndex as string | string[]) ?? '';
      
      // Check if the column is a date column and format the date
      if (dateColumns.includes(col.dataIndex as string)) {
        cellValue = formatDateTime(cellValue); // Format date as needed
      }

      // Escape any double quotes in the cell value and wrap the result in double quotes
      const escaped = ('' + cellValue).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    // Join the cell values to form a single CSV row
    csvRows.push(values.join(';'));
  });

  // Initiate a download from the CSV rows
  downloadListAsFile(csvRows);
};

/**
 * Initiates the download of a list as a file.
 * @param csvRows - An array of strings representing the CSV rows to be downloaded.
 * @param fileName - The desired name of the downloaded file. Defaults to 'export.csv'.
 */
export const downloadListAsFile = (csvRows: string[], fileName: string = 'export.csv') => {
  // Check if the csvRows array is empty. If it is, prompt the user to confirm download of an empty file.
  if (!(csvRows.length > 0)) {
    const shouldDownload = window.confirm('No items detected. Are you sure you want to download an empty file?')
    if (!shouldDownload) return;
  }

  // Check and append '.csv' extension to the fileName if it's not already present.
  if (!fileName.endsWith('.csv')) {
    fileName += '.csv';
  }

  // Create a blob from the CSV rows and initiate a download
  // Blob is a file-like object of immutable, raw data. Blobs represent data that isn't 
  // necessarily in a JavaScript-native format.

  // Combine all CSV rows, separating them with new line characters (\n) to form the CSV content
  // Blob type is set to 'text/csv;charset=utf-8;' to specify that the charset should be UTF-8
  const blob = new Blob([csvRows.join('\n')], { type: 'text/csv;charset=utf-8;' });

  // Create an object URL for the blob object. Object URLs allow you to create a URL 
  // for file-like objects (like Blob and File). This URL can be used to reference the 
  // data within the blob in web pages and scripts.
  const url = window.URL.createObjectURL(blob);

  // Create a temporary anchor ('<a>') element.
  // We use an anchor element for initiating the download as it supports the 'download' attribute.
  const a = document.createElement('a');

  // The anchor is hidden as it is not meant to be part of the document flow.
  a.setAttribute('hidden', '');

  // The href (hyperlink reference) attribute is set to the object URL created from the blob.
  // This is the URL the anchor element will navigate to, initiating the download.
  a.setAttribute('href', url);

  // The download attribute specifies the name for the file to be downloaded. Here, it is set to 'export.csv'.
  // When a user clicks on the anchor, the download of the file named 'export.csv' will begin.
  a.setAttribute('download', fileName);

  // Append the anchor to the document's body. This is necessary because an anchor must be part of the 
  // document to work correctly.
  document.body.appendChild(a);

  // Programmatically trigger a click event on the anchor.
  // This action simulates a user click on the link, initiating the file download.
  a.click();

  // Once the download is initiated, remove the anchor from the document.
  // This clean-up helps to prevent cluttering the document and potential memory leaks.
  document.body.removeChild(a);
};

/**
 * @param dateInput {string | string[]} A single value of the date or start and end date values
 * @param formatInput {string} The desired date format (default: 'DD.MM.YYYY')
 * @returns {string | null} A formatted date string or null if the input is invalid
 */
export function formatDateValue(dateInput: null | string | string[], formatInput = 'DD.MM.YYYY'): string | null {
    const isValidLength = (dateString: string) => dateString?.length >= 10 && dateString?.length <= 24;

    const formatDateString = (dateString: string): string => {
        const parsedDate = dayjs(dateString);
        return parsedDate.isValid() ? parsedDate.format(formatInput) : dateString;
    }

    if (typeof dateInput === 'string') {
        if (isValidLength(dateInput)) {
            return formatDateString(dateInput);
        } else {
            console.error('Date string does not match the expected length.');
            return dateInput;
        }
    } else if (Array.isArray(dateInput) && dateInput.length === 2) {
        const [startDate, endDate] = dateInput;
        if (isValidLength(startDate) && isValidLength(endDate)) {
            const formattedStartDate = formatDateString(startDate);
            const formattedEndDate = formatDateString(endDate);
            return `${formattedStartDate} - ${formattedEndDate}`;
        } else {
            console.error('One or both date strings do not match the expected length.');
            return `${dateInput[0]} - ${dateInput[1]}`;
        }
    } else {
        console.error('Invalid input. Input should be a string or an array of two strings.');
        return null;
    }
}

/**
 * Highlight function to wrap matched parts with span
 * @param text
 * @param search
 */
export const highlightText = (text: string, search: string, bg?: string) => {
  if (!search) return text;

  const regex = new RegExp(`(${search})`, 'gi'); // Case-insensitive matching
  const parts = text.split(regex);

  return parts.map((part, index) =>
    part.toLowerCase() === search.toLowerCase() ? (
      <span
        key={index}
        className={"search-highlight"}
        {...(bg ? {style: {backgroundColor: bg}} : {})}
      >
        {part}
      </span>
    ) : (
      part
    )
  );
};
