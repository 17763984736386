// ErrorPage.tsx
import { Button, Flex, Result, Typography } from 'antd';
import { isRouteErrorResponse, useNavigate, useParams, useRouteError } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExceptionStatusType, ResultStatusType } from 'antd/es/result';
import { useKeycloak } from '@App/settings/keycloak';

interface ErrorPageProps {
  errorCode?: number;
}

type ErrorData = {
  code: number;
  title: string;
  msg: string;
};

const errorMessages: ErrorData[] = [
  { code: 0, title: '', msg: 'An unknown error occurred.' },
  {
    code: 400,
    title: 'Bad Request',
    msg: 'The server could not understand the request due to invalid syntax.',
  },
  {
    code: 401,
    title: 'Unauthorized',
    msg: 'You do not have access to this resource.',
  },
  {
    code: 403,
    title: 'Forbidden',
    msg: 'You do not have the necessary permissions to access this resource.',
  },
  {
    code: 404,
    title: 'Page not found',
    msg: 'The requested page could not be found.',
  },
  {
    code: 405,
    title: 'Method Not Allowed',
    msg: 'The HTTP method used in the request is not supported by this resource.',
  },
  {
    code: 408,
    title: 'Request Timeout',
    msg: 'The server did not receive a complete request within the allowed time.',
  },
  {
    code: 429,
    title: 'Too Many Requests',
    msg: 'You have sent too many requests in a given amount of time.',
  },
  {
    code: 500,
    title: 'Internal Server Error',
    msg: 'An error occurred on the server.',
  },
  {
    code: 501,
    title: 'Not Implemented',
    msg: 'The server does not support the functionality required to fulfill the request.',
  },
  {
    code: 502,
    title: 'Bad Gateway',
    msg: 'The server received an invalid response from an upstream server.',
  },
  {
    code: 503,
    title: 'Service Unavailable',
    msg: 'The server is temporarily unable to handle the request.',
  },
  {
    code: 504,
    title: 'Gateway Timeout',
    msg: 'The gateway did not receive a timely response from the upstream server.',
  },
];

export const ErrorPage: React.FC<ErrorPageProps> = ({ errorCode }) => {
  const { code } = useParams<{ code?: string }>();
  const ec = errorCode || parseInt(code || '0', 10);
  const nav = useNavigate();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  
  const error = useRouteError() as {status?: number};
  let errorMessage: string;
  let errorTitle: string;

  if (isRouteErrorResponse(error)) {
    // error is type `ErrorResponse`
    const errorTxt = errorMessages.find((i) => i.code === error.status);
    // errorMessage = errorTxt ?? (error.error?.message || error.statusText);
    // errorMessage = errorMessages.find((i) => i.code === error.status) ?? error.statusText;
    errorTitle = errorTxt?.title || ''+ec;
    errorMessage = error.data;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  } else {
    console.error(error);
    errorMessage = 'Unknown error';
  }

  const errorData = errorMessages.find((i) => i.code == error.status || ec);
  
  const getErrorCode = ( ): ExceptionStatusType | ResultStatusType => {
    if (error?.status && [404, 403, 500].includes(error.status)) {
      return error.status as ExceptionStatusType;
    }
    return ec > 499 ? 'error' : 'warning';
  };

  const errorPageTitle = (
    <Typography.Title>
      {
        (errorData?.code != undefined && errorData?.code > 0 )
        ? errorData?.title
        : JSON.stringify(error, null,2)
      }

    </Typography.Title>
  );

  const errorPageActions = [
    <Button
      key='to-home'
      type='primary'
      onClick={() => nav('/')}
    >
      {t('buttons.homepage')}
    </Button>,
    <Button
      key='to-login'
      onClick={() => nav('/login')}
      hidden={keycloak?.authenticated}
    >
      {t('buttons.login')}
    </Button>,
    <Button
      key='go-back'
      onClick={() => nav(-2)}
    >
      {t('buttons.back')}
    </Button>,
  ];

  return (
    <Flex
      vertical
      justify='center'
      style={{
        minHeight: '100%',
      }}
    >
      <Result
        status={getErrorCode()}
        title={errorPageTitle}
        subTitle={errorMessage}
        extra={errorPageActions}
      />
    </Flex>
  );
};
