import { ActionMap } from './types';
import { ActionTypes, AllActions } from './';

type CounterPayload = {
  [ActionTypes.Update_counts]: Record<string, number>;
};

export type CounterActions =
  ActionMap<CounterPayload>[keyof ActionMap<CounterPayload>];

export const counterReducer = (
  state: Record<string, number> | null,
  action: AllActions
) => {
  switch (action.type) {
    case ActionTypes.Update_counts:
      return {
        ...state,
        ...action.payload
      };
   
    default:
      return state;
  }
};
