import React from 'react';
import { Button, ButtonProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { OmitProps } from 'antd/es/transfer/ListBody';

interface EditButtonProps {
  record: any;
  text?: string;
  iconOnly?: boolean;
  goTo?: string;
}

const EditButton: React.FC<EditButtonProps & ButtonProps> = ({
  text,
  record,
  iconOnly = false,
  goTo,
  ...rest
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    navigate(goTo ?? `${location.pathname}/${record.uuid ?? record.id}`);
  };

  return (
    <Button
      type="primary"
      icon={<EditOutlined />}
      onClick={handleClick}
      {...rest}
    >
      { iconOnly && ( text ?? 'Edit')}
    </Button>
  );
};

export default EditButton;