'use strict';

const isBuild = process.env.NODE_ENV === 'production';

const envValue = window.APP_CONFIG ?? process.env;
/**
 * @return {development|production|staging} accepted environment values
 */
 function getEnvironment(): 'development'|'production'|'staging' {
  const isHostStaging = window.location.hostname === 'demo.ak.ut.ee' ;
  const isHostProduction = window.location.hostname === 'ak.ut.ee';

  if (isBuild && isHostProduction) return 'production';
  if (isBuild && isHostStaging) return 'staging';
  return 'development';
}

const backendUrl = isBuild ? `https://${window.location.hostname}`  : 'http://localhost:8001';

const keycloak = {
  url: isBuild ? 'https://keycloak.hpc.ut.ee/' : 'http://localhost:8080/',
  realm: isBuild ? 'HPC' : 'master',
  clientId: getEnvironment() === 'production' ? 'AK-client' : 'AK-test',
}

const elasticApm = {
  serverUrl: 'https://apm.hpc.ut.ee',
  serviceName: 'ak-frontend',
  environment: getEnvironment()
}


const config: App.Config = {
  env: envValue.REACT_APP_ENV || getEnvironment(),
  backendUrl: envValue.REACT_APP_BACKEND_URL || backendUrl,
  keycloak,
  elasticApm,
};

export default config;
