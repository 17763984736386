import Keycloak from 'keycloak-js';
import React from 'react';

import config from '@AppConfig';

import Context from '@App/settings/keycloak';
import { LoadingPage } from '@App/views/LoadingPage';

type SessionTokens = {
  token: string | undefined;
  idToken: string | undefined;
  refreshToken: string | undefined;
};

const logger = (...other: unknown[]) => {
  if (config.env !== 'production') {
    console.info(...other);
  }
};

// Helper function to get session data from localStorage. This is used to persist the session across browser refreshes.
const getSessionData = (): SessionTokens => {
  const data = localStorage.getItem('session');
  return data ? JSON.parse(data) : {
    token: undefined,
    idToken: undefined,
    refreshToken: undefined
  };
};

// Helper function to set session data in localStorage. This is used to persist the session across browser refreshes.
const setSessionData = (data: SessionTokens) => {
  localStorage.setItem('session', JSON.stringify(data));
};

// Helper function to clear session data from localStorage. This is used when logging out or if there is an error.
const clearSessionData = () => {
  localStorage.removeItem('session');
};

/**
 * KeycloakProvider is a React component that handles Keycloak initialization, authentication,
 * token refreshing, and user profile loading. It provides the Keycloak instance, authentication status,
 * user profile data, and a function to get a valid token through context to its child components.
 *
 * @param {object} props The properties passed to the component.
 * @param {ReactNode} props.children The child components of KeycloakProvider.
 *
 * @returns {JSX.Element} Returns a KeycloakSessionData.Provider that provides Keycloak-related data to child components.
 *
 * @usage
 * ```jsx
 * import KeycloakProvider from './KeycloakProvider';
 * import App from './App';
 *
 * function Main() {
 *   return (
 *     <KeycloakProvider>
 *       <App />
 *     </KeycloakProvider>
 *   );
 * }
 * ```
 *
 * In this example, the KeycloakProvider wraps around the main App component,
 * thus providing Keycloak-related functionalities to all child components inside App.
 */
const KeycloakProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  // The current Keycloak instance, user profile, and authentication status are stored in state.
  const [userData, setUserData] = React.useState<Keycloak.KeycloakProfile | undefined>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const keycloakInitialized = React.useRef(false);
  
  // useMemo is used to hold the Keycloak instance
  const keycloak = React.useMemo(() => new Keycloak(config.keycloak), []);

  const handleAuthRefreshError = () => {
    logger('Error refreshing the token.');
    setUserData(undefined);
    clearSessionData();
  };
  
  const handleAuthError = (errorData: Keycloak.KeycloakError) => {
    logger('Authentication Error: ', errorData);
    clearSessionData();
  };

  const handleUserData = () => keycloak!.loadUserProfile()
  .then((profile) => setUserData(profile) )
  .catch(
    (error) => {
      console.error('Failed to load user profile: ', error);
      // Fallback to tokenParsed for user data
      if (keycloak?.tokenParsed) {
        setUserData({
          id: keycloak.tokenParsed.sub,
          username: keycloak.tokenParsed.preferred_username,
          email: keycloak.tokenParsed.email,
          firstName: keycloak.tokenParsed.given_name,
          lastName: keycloak.tokenParsed.family_name,
        });
      }
    }
  );

  React.useEffect(() => {
    if (!keycloakInitialized.current) {
      keycloakInitialized.current = true;

      if (!keycloak) return;

      const handleAuthSuccess = () => {
        setSessionData({
          token: keycloak.token,
          idToken: keycloak.idToken,
          refreshToken: keycloak.refreshToken,
        });
      };
      
      const handleAuthLogout = () => {
        logger('User logged out.');
        setUserData(undefined);
        clearSessionData();
      };

      // Event listeners for the Keycloak instance

      // onAuthSuccess and onAuthRefreshSuccess both store the updated tokens into the session storage
      keycloak.onAuthSuccess = handleAuthSuccess;
      keycloak.onAuthRefreshSuccess = handleAuthSuccess;

      // // onAuthLogout and onAuthRefreshError both clear the session data and reset user data in state.
      keycloak.onAuthLogout = handleAuthLogout;
      keycloak.onAuthRefreshError = handleAuthRefreshError;

      // This event is fired when there is an error during authentication.
      keycloak.onAuthError = handleAuthError;

      // If the token has expired, we attempt to silently update it.
      // If this fails, we clear session data and log the user out.
      keycloak.onTokenExpired = () => {
        logger('Token has expired.');
        keycloak?.updateToken(30).catch(() => keycloak?.logout());
      };

      // This event is fired when the adapter is initialized.
      keycloak.onReady = (isAuth) => {
        logger(`Keycloak was initialized. Authenticated: ${isAuth}`);
        setLoading(false);
      };

      // INIT Keycloak
      keycloak.init({
        onLoad: 'login-required',
        checkLoginIframe: false,
        redirectUri: window.location.origin + window.location.pathname,
        ...getSessionData(),
      })
      .then((isAuth) => {
        // Loads user profile if user is authenticated
        if (isAuth) {
          handleUserData()
        }
      })
      .catch((error) => {
        console.error('Failed to initialize Keycloak: ', error);
      }).finally(() => {
        setLoading(false);
      });
    }

    // Provide a cleanup function:
    return () => {
      // Remove event listeners.
      if (keycloak) {
        keycloak.onAuthSuccess = undefined;
        keycloak.onAuthRefreshSuccess = undefined;
        keycloak.onAuthLogout = undefined;
        keycloak.onAuthRefreshError = undefined;
        keycloak.onTokenExpired = undefined;
        keycloak.onReady = undefined;
        keycloak.onAuthError = undefined;
        // keycloak = null;
      }
    };
  }, [keycloak]);

  // Do not render children until Keycloak is fully initialized
  if (loading) {
    return <LoadingPage />;
  }

  // The Keycloak.current instance and related data are provided to child components via context.
  return (
    <Context.Provider value={{
      keycloak,
      authenticated: Boolean(keycloak.authenticated),
      userData,
    }}>
      {children}
    </Context.Provider>
  );
};

export default KeycloakProvider;
