import { Outlet, useLoaderData } from 'react-router-dom';
import { Col, Row } from 'antd';
import React from 'react';

import { handler } from '@App/settings/ApiHandler';
import { KC, useKeycloak } from '@App/settings/keycloak';
import PageWrapper from '@App/components/wrappers/PageWrapper';


type FormsData = API.GetForms & {
  isLoading: boolean;
  updateTime: number|null;
  doRefresh: () => void;
  setLoading: (l: boolean) => void;
}

export const PublicFormsData = React.createContext<FormsData>({
  forms: [],
  projects: [],
  ttr: [],
  granted: [],
  faculties: [],
  updateTime: null,
  isLoading: false,
  doRefresh: () => {},
  setLoading: () => {},
});

const getFormsData = (kc: KC, signal: AbortSignal) => handler<API.GetForms>({
  method: 'GET', path: '/v1/forms/', signal
}, kc);

export const FormsView: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [updateTime, setUpdateTime] = React.useState<number | null>(null);
  const [data, setData] = React.useState<API.GetForms>({
    ttr: [],
    forms: [],
    granted: [],
    projects: [],
    faculties: []
  });

  const {keycloak} = useKeycloak();

  const loaderData = useLoaderData() as API.GetForms;

  const fetchAllForms = () => {
    const abortController = new AbortController();
    setIsLoading(true);

    getFormsData(keycloak!, abortController.signal)
      .then((responseData) => {
        const currentTime = new Date().getTime();
        setUpdateTime(currentTime);
        setData(responseData);
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      abortController.abort();
    };
  };

  React.useEffect(() => {
    if (loaderData && 'forms' in loaderData) {
      const currentTime = new Date().getTime();
      setUpdateTime(currentTime);
      setData(loaderData);
    }
  }, []);

  return (
    <PageWrapper>
      <Row justify='center' style={{maxWidth:991}}>
        <Col span={24}>

          <PublicFormsData.Provider value={{
            isLoading,
            updateTime,
            doRefresh: fetchAllForms,
            setLoading: setIsLoading,
            ...data
          }}>
            <Outlet />
          </PublicFormsData.Provider>

        </Col>
      </Row>
    </PageWrapper>
  );
};
