import { Avatar, Button, Col, Collapse, Descriptions, Empty, List, Row, Tag, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { ApplicationState } from '@App/settings/StateManager';
import { DividerTitle } from '@App/components/texts/DividerTitle';
import { useKeycloak } from '@App/settings/keycloak';
import ContactInfo from '@App/components/ContactInfo';
import PageWrapper from '@App/components/wrappers/PageWrapper';


const NoFaculties: React.FC = () => (
  <Empty
    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
    imageStyle={{ height: 60 }}
    description={
      <span>
        Customize <a href="#API">Description</a>
      </span>
    }
  >
    <Button type="primary">Create Now</Button>
  </Empty>
);

export function ProfilePage() {
  const { state:{session} } = React.useContext(ApplicationState);
  const { keycloak, userData } = useKeycloak();
  const { t, i18n } = useTranslation(['forms', 'info']);
  const { token } = theme.useToken();

  return (
    <PageWrapper>

      <Row>
        <Col span={24}>

          {/* <Button.Group>

          <Button onClick={()=> console.log(session)}>session</Button>
          <Button onClick={()=> console.log(keycloak?.tokenParsed)}>keycloak</Button>
          <Button onClick={()=> console.log(userData)}>userData</Button>
          </Button.Group> */}

          <Typography.Title style={{ fontFamily: 'Rubik' }}>
            { t('pages.title.myData') }
          </Typography.Title>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <DividerTitle title={t('pages.user.info') } />

          <Descriptions layout="horizontal" >

            <Descriptions.Item label={t('site.label.utUsername') } span={3}>
              {userData?.username}
            </Descriptions.Item>

            <Descriptions.Item label={t('site.label.firstname') }>
              {userData?.firstName}
            </Descriptions.Item>

            <Descriptions.Item label={t('site.label.lastname') }>
              {userData?.lastName}
            </Descriptions.Item>
            
            <Descriptions.Item label={t('site.label.email') } >
              {userData?.email}
            </Descriptions.Item>

            {/* <Descriptions.Item label={t('forms.label.userFaculties') }>
            { session.faculties.length }
            </Descriptions.Item>

            <Descriptions.Item label={t('forms.label.userFaculties') }>
            <Button onClick={() => console.log(session)}>
              console
            </Button>
            </Descriptions.Item> */}

          </Descriptions>
        </Col>
      </Row>
      
      <Row>
        <Col span={24}>
          <DividerTitle title={t('pages.section.assignedFaculties') } />
          <List
            // header={t('forms.label.faculties') }
            itemLayout="vertical"
            dataSource={session.faculties}
            locale={{
              emptyText: t('')
            }}
            renderItem={(item: API.FacultyItem, index:number) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Tag
                      bordered={false}
                      // color={token.colorPrimary}
                      color="processing"
                    >
                      {item.code}
                    </Tag>
                  }
                  title={item.labels[i18n.language]}
                  // description={JSON.stringify(item.name, null, 2)}
                  />
              </List.Item>
            )}
            style={{ marginLeft:'2rem'}}
          />
        </Col>
      </Row>

      {/*
      <Row>
        <Col>
          <Descriptions layout="horizontal" column={1}>
            <Descriptions.Item label={t('label.access.roles', {ns: 'info'}) } >
              <pre>
              { JSON.stringify(keycloak?.realmAccess, null, 2)}
              </pre>
            </Descriptions.Item>
            <Descriptions.Item label={t('label.access.keycloak.userprofile', {ns: 'info'}) } >
              <pre>
              { JSON.stringify(keycloak?.tokenParsed?.['groups'], null, 2)}
              </pre>
            </Descriptions.Item>
            <Descriptions.Item label={t('label.access.resource', {ns: 'info'}) } >
              <pre>
              { JSON.stringify(keycloak?.resourceAccess, null, 2)}
              </pre>
            </Descriptions.Item>
            <Descriptions.Item label="KeyCloak" span={1}>
              <Collapse
                // onChange={onChange}
                items={[{
                  key: '1',
                  label: 'Keycloak data',
                  children: <pre style={{maxWidth:'55vw'}}>{JSON.stringify(keycloak, null, 2)}</pre>
                },
              ]}
              >
              </Collapse>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      */}

      <Row>
        <Col span={24}>
          <DividerTitle title={t('site.label.contact') } />
          <ContactInfo />
        </Col>
      </Row>

    </PageWrapper>
  );
}
