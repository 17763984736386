import { Button, Divider, Flex, Form, Input, notification, Select, Space, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';

import { handler } from '@App/settings/ApiHandler';
import { KC, useKeycloak } from '@App/settings/keycloak';
import { DividerTitle } from '@App/components/texts/DividerTitle';
import { DataContext } from '../../AdminFormView';
import { useTranslation } from 'react-i18next';
import { FormBuilder } from '@App/components/forms/QuestionnaireBuilderForm';


type GetData = { fields: Form.FieldType[], forms: Form.Questionary[] };
type FormPostResponse = { success?: string, error?: string };
interface FormCreatorProps {
  formInfo?: {
    name: string;
    title: string;
    status?: string;
  };
}

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22, offset: 1 },
};

const tailLayout = {
  wrapperCol: { offset: 0, span: 23 },
};

const generateSlug = (name: string) => {
  return name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
}
const postForm = (kc: KC, payload: API.PayloadType, uuid_slug?: string) => handler<FormPostResponse>({
  method: Boolean(uuid_slug) ? 'PUT' : 'POST',
  path: `/v1/manage/form/${uuid_slug ?? ''}`,
  payload
}, kc!);

export const ManageFormPage: React.FC<FormCreatorProps> = ({ formInfo }) => {
  const [action, setAction] = React.useState<API.ActionType>('new');
  const [isFormValid, setIsFormValid] = React.useState<boolean>(false);
  const [suggestedSlug, setSuggestedSlug] = React.useState<string>('');

  const { forms, groups, isLoading, setLoading} = React.useContext(DataContext);
  const { keycloak } = useKeycloak();
  const { identifier } = useParams();
  const { t } = useTranslation();
  const nav = useNavigate();
  
  const [form] = Form.useForm();
  const name = Form.useWatch('name', form) as string || '';
  const slug = Form.useWatch('slug', form) as string || '';
  
  const isMounted = React.useRef(false);
  const formValues = React.useRef<API.Form | Form.FormType>();
  
  const onFieldsChange = (vas: any, allFields: any[]) => {
    const areRequiredFieldsFilled = allFields.every(field => {
      if (field.rules?.some(rule => rule.required) || field.required) {
        return !!field.value;
      }
      return true;
    });
    setIsFormValid(areRequiredFieldsFilled);
  };
  
  const submitForm = (values: API.NewForm) => {
    setLoading(true);
    const payload = { action, values };
    postForm(
      keycloak!,
      payload,
      formValues.current?.slug ?? formValues.current?.uuid
    )
    .then((res) => {
      if (res.success) {
        notification.success({ message: t('forms.success.default','Form submitted successfully') });
      nav('/manage/forms/builder')
      }
    }).finally(() => setLoading(false));
  };
  
  const handleInitialValues = () => {
    const oldValues = forms.find(f => f.name == identifier || f.uuid == identifier)
    if (oldValues) {
      formValues.current = oldValues;
      form.setFieldsValue(oldValues);
      setAction('update')
    }
  };
  
  const validateSlug = async (_: any, value: string) => {
    if (!value) return Promise.resolve();
    const existingForm = forms.find(
      form => form.slug === value && form.uuid !== formValues.current?.uuid
    );
    return existingForm ? Promise.reject(new Error('Slug already exists')) : Promise.resolve();
  };

  const handleAutoSlugValue = async () => {
    if (!slug) {
      form.setFieldsValue({ slug: suggestedSlug });
    }
  };
  
  const checkSlugAlreadyExists = async (_: any, username: string) => {
    const currentFormUuid = form.getFieldValue(['slug']);
  
    // Check if the username exists in the users list, excluding the current user's uuid
    const isSlugTaken = forms.some(
      f => f.slug === username && f.uuid !== currentFormUuid
    );
  
    if (isSlugTaken) {
      // Throw error if username is taken
      return Promise.reject(new Error('Unikaalne nimi on juba võetud'));
      // return Promise.reject(new Error('Slug is already taken'));
    }
    
    return Promise.resolve();
  };
  
  React.useEffect(() => {
    if (formInfo) {
      form.setFieldsValue(formInfo);
    }
  }, [formInfo]);

  React.useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      if ( identifier && !['new','add','create'].includes(identifier) ) {
        handleInitialValues()
      }
    }

    return () => {
      console.info('FormCreator unmounted');
    };
  }, []);

  React.useEffect(() => {
    if (name) {
      const slug = generateSlug(name);
      setSuggestedSlug(slug);
    } else {
      setSuggestedSlug('');
    }
  }, [name]);
  
  return (
    <div>
      {/* <DividerTitle title={t(`forms.title.${action}Form`)} /> */}
      
      <Divider>{t(`forms.title.${action === 'add' ? 'new': action}Form`)}</Divider>

      <Form
        {...layout}
        form={form}
        name='control-ref'
        onFinish={submitForm}
        layout='vertical'
        onFieldsChange={onFieldsChange}
        style={{ maxWidth: 600, margin:'0 auto' }}
      >
        <Form.Item
          name='uuid'
          label='UID'
          tooltip={t('forms.tooltip.uid')}
          hidden={['new', 'add','create'].includes(action)}
        >
          <Input readOnly disabled showCount maxLength={36} />
        </Form.Item>
        
        <Form.Item
          name='type'
          label={t('forms.label.formType')}
          validateTrigger={['onChange', 'onBlur']}
          required={true}
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Please input option's value or delete this field.",
            },
          ]}
        >
          <Select
            options={[
              { value: 'artikkel_30', label: 'TTR form'},
              { value: 'research_project', label: 'Research Project form'},
              { value: 'quiz', label: 'Quiz questionnaires', disabled: true},
            ]}
          />
        </Form.Item>

        <Form.Item
          name='name'
          label={t('forms.label.name')}
          rules={[{ required: true }]}
          required={true}
        >
          <Input
            showCount
            maxLength={255}
            required={true}
            // onBlur={handleAutoSlugValue}
            // placeholder='Unique form name. NOT displayed to user.'
          />
        </Form.Item>

        <Form.Item
          name='slug'
          label={t('forms.label.slug')}
          tooltip={t('forms.tooltip.slug')}
          validateTrigger='onBlur'
          required={true}
          rules={[
            { required: true, message: "Please input the form's slug." },
            { validator: validateSlug },
          ]}
          // help={suggestedSlug && `Suggested slug: ${suggestedSlug}`}
        >
          <Input
            placeholder={suggestedSlug}
            autoComplete='false'
            onBlur={handleAutoSlugValue}
            required={true}
          />
        </Form.Item>
        
        <Form.Item
          name='title'
          label={t('forms.label.formTitle')}
          rules={[
            { required: true },
          ]}
          required={true}
        >
          <Input
            showCount
            maxLength={255}
            required={true}
            placeholder="Title of the form that's displayed to user incase the name has no corresponding translation.."
          />
        </Form.Item>

        <Form.Item
          name='description'
          label={t('forms.label.formDescription')}
          rules={[{ required: false }]}
        >
          <Input.TextArea
            showCount
            maxLength={2047}
          />
        </Form.Item>

        <Form.Item
          name='status'
          label={t('forms.label.formStatus')}
          initialValue='draft'
          rules={[{ required: false }]}
        >
          <Select
            allowClear
            placeholder='Select a status'
            options={[
              { value: 'pending', label: 'Pending' },
              { value: 'draft', label: 'Draft' },
              { value: 'deleted', label: 'Deleted' },
              { value: 'inactive', label: 'Inactive' },
              { value: 'active', label: 'Active' },
            ]}
          />
        </Form.Item>

        <Form.Item
          name='groups'
          label={t('forms.label.formGroupsPermissions')}
          rules={[{ required: false }]}
        >
          <Select
            allowClear
            placeholder='Select who can fill the form'
            options={ groups.map( ({label, name, id}) =>
              ({ value: id ?? name, label: label ?? name })
            )}
          />
        </Form.Item>

        <Form.Item noStyle {...tailLayout}>
          <Flex justify={'space-between'} style={{marginRight: '4.16%'}}>
            <Space direction='vertical'>
              <Button type='text' htmlType='button' onClick={() => form.resetFields()}>
                {t(`forms.btn.reset`)}
              </Button>
              <Button type='link' onClick={() => nav(-1)}>
                {t(`buttons.back`)}
              </Button>
            </Space>
            
            <Button
              type={isFormValid ? 'primary' : undefined}
              htmlType='submit'
              loading={isLoading}
              // disabled={!isFormValid}
            >
              {t(`forms.btn.${action}`)}
            </Button>

          </Flex>
        </Form.Item>

      </Form>
    </div>
  );
};
