import {
  Button, Col, Form, Input, Select, Space, Switch, Typography, message, Row, Flex,
} from 'antd';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Keycloak from 'keycloak-js';
import React from 'react';

import { DividerTitle } from '@App/components/texts/DividerTitle';
import { handler } from '@App/settings/ApiHandler';
import { UserForm } from '@App/components/forms/UserForm';
import { useKeycloak } from '@App/settings/keycloak';


const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

type UserResponse = Responses.Default & {
  user: API.UserType;
  item?: API.UserType;
};

const getUser = (keycloak: Keycloak, uuid: string) => handler<UserResponse>({
  method: 'GET',
  path: '/v1/manage/user/' + uuid,
}, keycloak);

const postUser = (kc: Keycloak, payload: API.PayloadType, uuid?: string) => handler<UserResponse>({
  method: uuid ? 'PUT' : 'POST',
  path: '/v1/manage/user/' + (uuid ?? ''),
  payload,
}, kc)

export const UserFormPage: React.FC = () => {
  const [action, setAction] = React.useState<'new' | 'update'>('new');
  const currentUserValues = React.useRef<API.UserType>();
  const [ form ] = Form.useForm();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { uuid } = useParams();
  const loaderData = useLoaderData() as UserResponse;
  const nav = useNavigate();

  const postUserData = (values: object) => {
    const payload = {
      action,
      values,
      ...(
        currentUserValues.current &&
        'last_updated' in currentUserValues.current
        ? {lastUpdated: currentUserValues.current.last_updated}
        : {}
      )
    };

    postUser(keycloak!, payload, uuid)
    .then((data) => {
      console.log(data)
      if (data.success) {
        nav('/manage/access/users/')
      }
      // if ('error' in data && typeof(data)== 'string' ) {
      //   message.error(data['error'])
      // }
    });
  };

  const onFinish = (values: any) => {
    postUserData(values)
  };

  // Assuming 'loaderData' has the user data structure needed for the form
  React.useEffect(() => {
    if (loaderData && 'user' in loaderData) {
      currentUserValues.current = loaderData.user;
      form.setFieldsValue(loaderData.user);
      setAction('update')
    }
  }, [loaderData, form]);

  return (
    <div style={{ padding: '1rem 0'}}>
      {/* <AsyncModal title={action === 'new' ? 'Add new user' : 'Update user'} /> */}
      <DividerTitle
        // title={action === 'new' ? 'Add new user' : 'Update user'}
        title={t(`forms.title.${action}User`) }
        orientation="center"
        style={{ margin: '1.6rem 1rem'}}
      />
      <Row justify={'center'}>
        <Col lg={16} md={{span: 20}} xs={{span: 24}}>

          <UserForm
            {...layout}
            form={form}
            action={'new'}
            name={action === 'new' ? 'Add new user' : 'Update user'}
            onFinish={onFinish}
            layout='horizontal'
            initialValues={currentUserValues.current}
          >
            <Form.Item noStyle>
              <Flex gap={'.5rem'} justify={'space-between'}>
                <Button
                  htmlType='reset'
                  shape='round'
                  onClick={() => form.resetFields()}
                >
                  {t(`forms.btn.reset`)}
                </Button>
                <Button
                  type={'primary'}
                  htmlType='submit'
                  shape='round'
                >
                  {t(`forms.btn.${action === 'new' ? 'create' : 'update'}`)}
                </Button>
              </Flex>
            </Form.Item>
          </UserForm>
        </Col>
      </Row>

    </div>
  );
};
