import { Button, Divider, Form, Input, Select, Space, Spin, Typography } from 'antd';
import { ReloadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import Keycloak from 'keycloak-js';

import { handler } from '@App/settings/ApiHandler';
import { DividerTitle } from '@App/components/texts/DividerTitle';
import { useKeycloak } from '@App/settings/keycloak';
import JsonValidator from '@App/components/fields/JsonValidator';
import { DataContext } from '../AccessView';
import { GroupForm } from '@App/components/forms/GroupForm';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

type PermissionType = {
  id?: string;
  name: string;
  label: string;
  role: string;
};

const getGroupData = (kc: Keycloak, id: string|number) => handler<PermissionType>({
  method: 'GET', path: '/v2/manage/permission/' + id,
}, kc);

const postNewGroup = (kc: Keycloak, payload: API.PayloadType) => handler<PermissionType>({
  method: 'POST', path: '/v2/manage/permission/new', payload
}, kc);
const updateNewGroup = (kc: Keycloak, payload: API.PayloadType, id:string) => handler<PermissionType>({
  method: 'POST', path: '/v2/manage/group/'+id, payload
}, kc);

const defaultValues = { name: '', label: '', role: '' };

export const GroupPage: React.FC = () => {
  const [action, setAction] = React.useState<'new' | 'update'>('new');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [permission, setPermission] = React.useState<PermissionType>(defaultValues);
  const { id } = useParams();
  const [form] = Form.useForm();
  const nav = useNavigate();
  const { keycloak } = useKeycloak();
  const {faculties, permissions} = React.useContext(DataContext);
  
  const oldValues = React.useRef<PermissionType|null>(null);
  const isMounted = React.useRef(false);

  const getData = () => {
    setIsLoading(true);
    
    id && getGroupData(keycloak!, id)
      .then( (data) => {
        setAction('update');
        setPermission(data);
        oldValues.current = data;
        form.setFieldsValue(data);
      }).catch(() => {

      })
      .finally( () => {
        setIsLoading(false)
      });
  };
  
  const onFinish = (values: PermissionType) => {
    if (action == 'new') {
      postNewGroup(keycloak!, { action, ...values })
    }
    else {
      updateNewGroup(keycloak!, { action, newValues: values }, id!)
    }
    console.log('on finish finished')
  };

  const onReset = () => {};
  const onDelete = () => {};
  
  React.useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      if ( id && !['new','add','create'].includes(id) ) {
        getData();
      }
    }

    return () => {
      console.info('unmounted');
    };
  }, []);
  
  return (
    <div>

      <DividerTitle
        title={(action === 'new' ? 'Create new' : 'Update') + ' group'}
        orientation="center"
      />

      <Spin spinning={isLoading}>

        <GroupForm
          {...layout}
          action={'new'}
          form={form}
          name={action === 'new' ? 'Add new user' : 'Update user'}
          onFinish={onFinish}
          layout='horizontal'
          // initialValues={permission}
          style={{ maxWidth: 600 }}
        >
          <Form.Item {...tailLayout}>
            <Space.Compact block>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
              <Button htmlType='button' onClick={onReset}>
                Reset
              </Button>
              {
                action === 'update' && (
                  <Button danger htmlType='button' onClick={onDelete}>
                    delete
                  </Button>
                )
              }
            </Space.Compact>
          </Form.Item>
        </GroupForm>

        {/* <Form
          {...layout}
          form={form}
          name={action === 'new' ? 'Add new user' : 'Update user'}
          onFinish={onFinish}
          layout='horizontal'
          initialValues={permission}
          style={{ maxWidth: 600 }}
        >
          <Form.Item name='name' label='Name' rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name='label' label='Label' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          
          <Form.Item name='description' label='Description' rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name='faculties' label='Group faculty(ies)' rules={[{ required: false }]}>
            <Select placeholder='Select who can fill the form' allowClear>
            {
              faculties.map( (f) => !f.category && (
                <Select.Option key={f.code} value={f.id ?? f.code}>
                  <Typography.Text strong>
                    {f.code}
                  </Typography.Text>
                  {' '}
                  {f.label ?? f.name}
                </Select.Option>
              ))
            }
            </Select>
          </Form.Item>
          
          <Form.Item name='permissions' label='Group permission(s)' rules={[{ required: false }]}>
            <Select placeholder='Select group permissions' allowClear>
            {
              permissions.map( (g) => (
                <Select.Option key={g.name} value={g.id ?? g.name}>
                  { g.label ?? g.name }
                </Select.Option>
              ))
            }
            </Select>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Space.Compact block>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
              <Button htmlType='button' onClick={onReset}>
                Reset
              </Button>
              {
                action === 'update' && (
                  <Button danger htmlType='button' onClick={onDelete}>
                    delete
                  </Button>
                )
              }
            </Space.Compact>
          </Form.Item>

        </Form> */}
       
      </Spin>
{/* 
      <JsonValidator
        path={`/v2/manage/permissions/${action}`}
        action={action}
      /> */}

    </div>
  );
};
