import React from 'react';
import ReactDOM from 'react-dom/client';

import { Application } from '@AppRoot';
import { LoadingPage } from './views/LoadingPage';
import { StateManager } from '@App/settings/StateManager';
import KeycloakProvider from '@App/settings/keycloak/KeycloakProvider';

import 'antd/dist/reset.css';

import '@App/assets/css/fonts.css';
import '@App/assets/css/index.less';
import '@App/assets/css/buttons.less';
import '@App/assets/css/table.less';

import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <KeycloakProvider>
      <StateManager>
        <React.Suspense fallback={<LoadingPage />}>
          <Application />
        </React.Suspense>
      </StateManager>
    </KeycloakProvider>
);
