import {
  LogoutOutlined, LoginOutlined, GlobalOutlined, MenuUnfoldOutlined, MenuFoldOutlined
} from '@ant-design/icons';
import { Grid, Layout, Menu, MenuProps, SiderProps, Switch, Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useTranslation } from 'react-i18next';

import { ActionTypes } from '@App/settings/reducers';

import { ApplicationState } from '@App/settings/StateManager';
import { useKeycloak } from '@App/settings/keycloak';
import { createKeyPathMapping, newNav, userHasAccess } from '@App/settings/navigation';
import { Language } from '@AppRoot';

import CircleLogo from '@App/assets/images/ut-circle-blue.svg';
import MainBlueLogo from '@App/assets/images/pealogo_eng_sinine.svg';
import PeaSinineLogo from '@App/assets/images/pealogo_est_sinine.svg';

import '@App/assets/css/sidebar.less'
import { SidebarCloseBtn } from '../buttons/SidebarCloseBtn';
import { ProfileMenu } from './ProfileMenu';


type MenuItems = Required<MenuProps>['items'];
type MenuItem = MenuItems[number];

interface IHeaderProps extends SiderProps {
  role?: 'public' | 'user' | 'admin';
  navItems?: MenuItem[];
  onCollapse?: (collapse: boolean) => any;
  isCollapsed?: boolean;
}

const SIDEBAR_WIDTH_COLLAPSED = 60; // Width for collapsed state (icons only)
const SIDEBAR_WIDTH_EXPANDED = 250; // Width for expanded state

export const AppSidebar: React.FC<IHeaderProps> = ({
  isCollapsed = false,
  role = 'admin',
  ...rest
}) => {
  const { state, dispatch } = React.useContext(ApplicationState);
  const screens = Grid.useBreakpoint();
  const location = useLocation();
  const nav = useNavigate();
  const { t } = useTranslation();
  const {keycloak, authenticated, userData} = useKeycloak();
  const [isUserTriggeredCollapse, setIsUserTriggeredCollapse] = React.useState(false);
  const [userPreferredCollapsed, setUserPreferredCollapsed] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(isCollapsed);


  const handleLogInOut = () => {
    if (authenticated) {
      keycloak?.logout();
    } else {
      keycloak?.login();
    }
  };

  const handleSidebarOpen = (isOpen: boolean, type: 'user' | 'breakpoint') => {
    if (type === 'user') {
      dispatch({
        type: ActionTypes.Set_sidebar,
        payload: { isCollapsed: isOpen }
      });
    } else if (type === 'breakpoint') {
      // Handle automatic collapsing/expanding based on breakpoints
      if (screens.xs && !isUserTriggeredCollapse) {
        // Collapse on small screens if not user-triggered
        setIsOpen(isOpen)
        // dispatch({
        //   type: ActionTypes.Set_sidebar,
        //   payload: { isCollapsed: true }
        // });
      }
    }
  };

  const setIsSidebarCollapsed = (isCollapsed: boolean) => {
    dispatch({
      type: ActionTypes.Set_sidebar,
      payload: { isCollapsed }});
  }

  const handleSidebarToggle = () => {
    setIsUserTriggeredCollapse(true);
    dispatch({type: ActionTypes.Toggle_sidebar, payload: {}});
  };

  const handleThemeToggle = () => {
    dispatch({
      type: ActionTypes.Change_theme,
      payload: {
        theme: state.theme === 'light' ? 'dark' : 'light',
      },
    });
  };

  const handleLangToggle = () => {
    const language = state.language === Language.ET ? Language.EN : Language.ET;
    dispatch({
      type: ActionTypes.Change_language,
      payload: { language },
    });
  };

  const paths = createKeyPathMapping();

  const formatNavItem = ({label, ...rest}: App.NavItem | App.NavGroup): ItemType=> {
    return { ...rest, label: t(label) }
  }

  const pathKey = Object.entries(paths).find(p => p[1] === location.pathname)?.[0] ?? '';
  const activeMenuKeys = (pathKey.split('-').map((k,i) => i === 0 ? k : pathKey)) ?? [];

  const mainMenuItems = newNav
    .filter(
      userHasAccess(state.session.permissions) ||
      userHasAccess(keycloak?.realmAccess?.roles)
    )
    .map((pageItem) => ('items' in pageItem || 'children' in pageItem)
      ? {
          type: 'submenu',
          ...pageItem,
          label: t(pageItem.label),
          children: pageItem.items
            .filter(
              userHasAccess(state.session.permissions) ||
              userHasAccess(keycloak?.realmAccess?.roles)
            )
            .map(formatNavItem)
        }
      : formatNavItem(pageItem)
    );

  const settingsMenu: MenuItems = [
    {
      key: 'change-lang',
      icon: <GlobalOutlined style={{fontSize:'1.1rem'}} />,
      onClick: handleLangToggle,
      title: 'Current language ' + state.language,
      label: state.language
    },
    {
      key: 'change-theme',
      // icon: state.theme,
      onClick:  handleThemeToggle,
      label: (
        <Switch
          checkedChildren="dark"
          unCheckedChildren="light"
          checked={state.theme === 'dark'}
        />
      )
    },
    {
      key: 'log-in-out',
      icon:  keycloak?.authenticated ? <LogoutOutlined style={{fontSize:'1.1rem'}} /> : <LoginOutlined style={{fontSize:'1.2rem'}} />,
      onClick:  handleLogInOut,
      label: keycloak?.authenticated ? ('Logout ' + (userData?.username ?? '')) : 'Login',
    },
  ];

  // React.useEffect(() => {
  //   // Adjust sidebar state based on screen size
  //   if (screens.xs) {
  //     dispatch({
  //       type: ActionTypes.Set_sidebar,
  //       payload: { isCollapsed: true }
  //     });
  //   }
  // }, [screens]);

  return (
    <Layout.Sider
      collapsible
      trigger={null}
      // collapsedWidth={screens.xs ? 0 : 60}
      // onCollapse={handleSidebarOpen}
      breakpoint="lg"
      // onBreakpoint={broken => setIsOpen(broken)}
      collapsed={state.isSidebarCollapsed ?? !screens.md}
      // onCollapse={(collapsed, type) => handleSidebarOpen(collapsed, type === 'responsive' ? 'breakpoint' : 'user')}
      collapsedWidth={screens.xs ? 0 : SIDEBAR_WIDTH_COLLAPSED}
      theme={state.theme !== 'auto' ? state.theme : 'dark'}
      width={SIDEBAR_WIDTH_EXPANDED}
      className='app-sidebar'
    >
      <div className='sidebar-wrapper'>
        <div className='sidebar-nav'>
          <div
            className='logo-wrapper'
            onClick={() => nav('/')}
            style={{
              color: state.theme == 'dark' ? '#ffffff80' : '#2C5697',
              cursor: 'pointer'
            }}
          >
            {
              state.isSidebarCollapsed
                ? <CircleLogo />
                : state.language == Language.ET
                  ? <PeaSinineLogo />
                  : <MainBlueLogo />
            }
          </div>
          <Menu
            theme={state.theme !== 'auto' ? state.theme : 'dark'}
            mode="inline"
            items={mainMenuItems}
            selectedKeys={activeMenuKeys}
            onClick={(e: {key: string}) => nav(paths[e.key] )}
          />
        </div>
        <div className='sidebar-actions'>
        <ProfileMenu />
          {/* <Menu
            mode="inline"
            theme={state.theme !== 'auto' ? state.theme : 'dark'}
            items={[
              {
                key: 'collapse-sidebar',
                label: t('settings.btn.shrink'),
                icon: state.isSidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />,
                onClick: handleSidebarToggle
              }
            ]}
          /> */}
        </div>
        <SidebarCloseBtn />
      </div>

    </Layout.Sider>
  );
};
