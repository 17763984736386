import { SearchOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Avatar,
  Button,
  Col,
  Collapse,
  Divider,
  Input,
  List,
  Row,
  Typography,
} from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';



interface Category {
  title: string;
  results: string[];
}

const suffix = (
  <SearchOutlined
    style={{
      fontSize: 16,
      color: '#1890ff',
    }}
  />
);

const searchCategories: Category[] = [
  { title: 'Category 1', results: ['Result 1', 'Result 2', 'Result 3'] },
  { title: 'Category 2', results: ['Result 4', 'Result 5', 'Result 6'] },
  { title: 'Category 3', results: ['Result 7', 'Result 8', 'Result 9'] },
];

export const SearchPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSearch = (value: string) => {
    console.log(`Searching for: ${value}`);
    setIsLoading((prev) => !prev);
  };

  const handleInputChange = (value: string) => {
    setSearchTerm(value);
  };

  return (
    <div style={{ padding: '2rem' }}>
      <Typography.Title type='secondary' style={{ textAlign: 'center' }}>
        Search
      </Typography.Title>

      <Row gutter={[20, 24]}>
        <Col span={14} offset={6}>
          <Input.Search
            placeholder='search ... '
            enterButton='Search'
            size='large'
            suffix={suffix}
            loading={isLoading}
            onSearch={handleInputChange}
          />
        </Col>
      </Row>

      <Divider />

      <Typography.Text italic type='secondary'>
        Quick search by most popular or categories
      </Typography.Text>

      <Button.Group>
        <Button type='ghost'>Category 1</Button>
        <Button type='link'>Category 2</Button>
        <Button type='link'>Category 3</Button>
      </Button.Group>

      <Divider />

      <Typography.Text italic type='secondary'>
        Result
      </Typography.Text>

      <Outlet />
      
      <Row>
        <Col span={24} offset={0}>
          <List
            pagination={{
              position: 'bottom',
              align: 'center',
            }}
            dataSource={searchCategories}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar />
                  }
                  title={<a href='/'>{item.title}</a>}
                  description='Ant Design, a design language for background applications, is refined by Ant UED Team'
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </div>
  );
};
