import { ActionTypes, AllActions } from './';
import { ActionMap } from './types';


type SidebarPayload = {
  [ActionTypes.Set_sidebar]: {
    isCollapsed: boolean | null
  }
  [ActionTypes.Toggle_sidebar]: {}

};

export type SidebarActions =
  ActionMap<SidebarPayload>[keyof ActionMap<SidebarPayload>];

export const sidebarReducer = (state: boolean, action: AllActions) => {
  const ls = localStorage;
  switch (action.type) {
    case ActionTypes.Set_sidebar:
      if (typeof window !== 'undefined') {
        ls.setItem('isSidebarCollapsed', action.payload.isCollapsed + '');
      }
      return action.payload.isCollapsed;
    
    case ActionTypes.Toggle_sidebar:
        if (typeof window !== 'undefined') {
          ls.setItem('isSidebarCollapsed', !state + '');
        }
        return !state;
  
    default:
      return state;
  }
};
