import { Grid, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { ApplicationState } from '@App/settings/StateManager';
import { Language } from '@AppRoot';

import CircleLogo from '@App/assets/images/ut-circle-blue.svg';
import MainBlueLogo from '@App/assets/images/pealogo_eng_sinine.svg';
import PeaSinineLogo from '@App/assets/images/pealogo_est_sinine.svg';


const colors = {
  st0: { fill: '#2C5697' },
  st1: { fill: '#4B4B4B' },
  st2: { fill: '#FFFFFF' }
};

export const AppLogo: React.FC = () => {
  const { state } = React.useContext(ApplicationState);
  const screen = Grid.useBreakpoint();
  const { token } = theme.useToken();

  return (
    <div
      className='logo-wrapper'
      style={{
        minWidth: 200,
        maxHeight: 56,
        paddingTop: 6,
        color: state.theme == 'dark' ? '#ffffff80' : '#2C5697'
      }}
    >
    {
      !screen.md
        ? <CircleLogo />
        : state.language == Language.ET
          ? <PeaSinineLogo />
          : <MainBlueLogo />
    }
    </div>
  );
};

export const UTLogo: React.FC = () => {
  const { state } = React.useContext(ApplicationState);
  const { i18n } = useTranslation();
  
  return (
    <div
      className='logo-wrapper'
      style={{
        color: state.theme == 'dark' ? '#ffffff' : '#2C5697'
      }}
    >
    {
      i18n.language.includes( Language.ET )
        ? <PeaSinineLogo />
        : <MainBlueLogo />
    }
    </div>
  );
};
