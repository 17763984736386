import {
  AppstoreOutlined, BarsOutlined, ReloadOutlined, PlusOutlined, ArrowLeftOutlined
} from '@ant-design/icons';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Grid, Row, Segmented, Space, Tag, Typography } from 'antd';
import React from 'react';

import { handler } from '@App/settings/ApiHandler';
import { DividerTitle } from '@App/components/texts/DividerTitle';
import { KC, useKeycloak } from '@App/settings/keycloak';
import { t } from 'i18next';
import PageWrapper from '@App/components/wrappers/PageWrapper';


type FormsDataLists = {
  forms: Array<API.Form|Form.FormType>;
  groups: API.GroupType[];
  fields: API.Field[];
  projects: API.ProjectNames[];
}

type FormsData = FormsDataLists & {
  isLoading: boolean;
  updateTime: number|null;
  setLoading: (e: boolean) => void;
  onRefresh: () => void;
  doRefresh: () => void;
}

export const DataContext = React.createContext<FormsData>({
  forms: [],
  fields: [],
  groups: [],
  projects: [],
  updateTime: null,
  isLoading: false,
  setLoading:() => {},
  onRefresh: () => {},
  doRefresh: () => {},
});

const getFormsData = (kc: KC, signal: AbortSignal) => handler<FormsDataLists>({
  method: 'GET', path: '/v2/manage/forms/', signal
}, kc);

export const AdminFormView: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState<number|string>('builder');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [updateTime, setUpdateTime] = React.useState<number | null>(null);
  const [data, setData] = React.useState<FormsDataLists>({
    forms: [],
    fields: [],
    groups: [],
    projects: [],
  });
  
  const location = useLocation();
  const {keycloak} = useKeycloak();
  const nav = useNavigate();
  const { sm } = Grid.useBreakpoint();

  const getData = () => {
    const abortController = new AbortController();
    setIsLoading(true);

    getFormsData(keycloak!, abortController.signal)
      .then((responseData) => {
        const currentTime = new Date().getTime();
        setUpdateTime(currentTime);
        setData(responseData);
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error(error);
          // setIsLoading(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      abortController.abort();
    };
  };
  
  React.useEffect(() => {
    const cleanup = getData();
    
    return () => {
      cleanup();
    };
  }, []);

  React.useEffect(() => {
    const pathArray = location.pathname.split('/') ;
    // console.log('location changed', cleanup);
    const activeValue = pathArray.slice(3).join('/')
    
    // console.log(activeValue)
    setActiveTab(activeValue);
    
    return () => {};
  }, [location.pathname]);

  const subPagesNav = [
    {
      // label: 'Form builder',
      label: t('links.manage.forms'),
      value: 'forms/builder',
      icon: <AppstoreOutlined />,
    },
    {
      // label: 'Form builder',
      label: t('forms.link.add.form'),
      value: 'forms/new',
      icon: <PlusOutlined />,
    },
  ]
  const subPagesNav2 = [ {
      // label: 'Fields',
      label: t('links.fields'),
      value: 'forms/fields',
      icon: <BarsOutlined />,
    },
    {
      // label: 'Form builder',
      label: t('forms.link.add.field'),
      value: 'forms/fields/new',
      icon: <PlusOutlined />,
    },
  ];

  const dataPagesNav = [
    {
      // label: 'Form builder',
      label: t('links.projects'),
      value: 'ttr/projects',
      icon: <AppstoreOutlined />,
    },
    {
      // label: 'Form builder',
      label: t('links.TTRlist'),
      value: 'ttr/art30Projects/',
      icon: <AppstoreOutlined />,
    },
    {
      // label: 'Form builder',
      label: t('links.researchProjects'),
      value: 'ttr/researchProjects/',
      icon: <AppstoreOutlined />,
    },
  ];

  return (
    <>
      {/* <Divider orientation="center" style={{ margin: '1.6rem 0 1rem'}}> */}
        {/* <Typography.Text
          color='secondary'
          style={{
            margin: 0,
            marginRight: 8,
            fontSize: '.9em'
          }}
        >
        </Typography.Text> */}
        <PageWrapper>
          <Row gutter={[16, 16]} justify='space-between' style={{  maxWidth: 991,margin: '1rem 0'}}>
            <Col xs={24} sm={12} style={{ alignItems: 'end' }}>
              <Space direction='vertical'>
              {
                dataPagesNav.map( (item, itemIdx) => {
                  return (
                    <Tag.CheckableTag
                      key={`${itemIdx}-${item.value}`}
                      checked={location.pathname == `/manage/${item.value}`}
                      onChange={(c) => c && nav(`/manage/${item.value}`) }
                    >
                      <Space>
                        {item.icon}
                        {item.label}
                      </Space>
                    </Tag.CheckableTag>
                  )
                })
              }
              </Space>
            </Col>
            <Col xs={24} sm={12} style={{ alignItems: 'end' }}>
              <Row style={{ marginBottom: 8 }}>
                {
                  subPagesNav.map( (item, itemIdx) => {
                    return (
                      <Col span={11} key={itemIdx} >
                        <Tag.CheckableTag
                          key={`${itemIdx}-${item.value}`}
                          checked={location.pathname == `/manage/${item.value}`}
                          onChange={(c) => c && nav(`/manage/${item.value}`) }
                          >
                          <Space>
                            {item.icon}
                            {item.label}
                          </Space>
                        </Tag.CheckableTag>
                      </Col>
                    )
                  })
                }
              </Row>
              <Row style={{ marginBottom: 8 }}>
                {
                  subPagesNav2.map( (item, itemIdx) => {
                    return (
                      <Col span={11} key={itemIdx} >
                        <Tag.CheckableTag
                          key={`${itemIdx}-${item.value}`}
                          checked={location.pathname == `/manage/${item.value}`}
                          onChange={(c) => c && nav(`/manage/${item.value}`) }
                          >
                          <Space>
                            {item.icon}
                            {item.label}
                          </Space>
                        </Tag.CheckableTag>
                      </Col>
                    )
                  })
                }
              </Row>
            </Col>
          </Row>
      </PageWrapper>
      {/* </Divider> */}

      {/* <Row gutter={8}>
        <Col span={12}>
          <Button.Group>
            <Button onClick={() => nav(-1)} icon={<ArrowLeftOutlined />}>
              {t('buttons.back')}
            </Button>
            <Button
              icon={<PlusOutlined />}
              onClick={()=> nav(`/manage/forms/${activeTab}/new`)}
            >
              {t('forms.link.add.form')}
            </Button>
            <Button icon={<ReloadOutlined />} onClick={() => getData()}>
              {t('buttons.refresh')}
            </Button>
          </Button.Group>
        </Col>
      </Row> */}
      
      <DataContext.Provider value={{
        isLoading,
        updateTime,
        onRefresh: getData,
        doRefresh: () => getData(),
        setLoading: setIsLoading,
        ...data
      }}>
        <Outlet />
      </DataContext.Provider>

    </>
  );
};
