import {
  PlusOutlined, EyeOutlined
} from '@ant-design/icons';
import {
  Button, Col, Divider, Flex, Form, Grid, Input, Modal, notification,
  Row, Select, Skeleton, Space, Typography
} from 'antd';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { handler } from '@App/settings/ApiHandler';
import { KC, useKeycloak } from '@App/settings/keycloak';
// import { DividerTitle } from '@App/components/texts/DividerTitle';
// import { FormBuilder } from '@App/components/forms/QuestionnaireBuilderForm';
import settings from '@App/components/settings';
import AddOptions from '@App/components/fields/AddOption';
import DeleteButton from '@App/components/buttons/DeleteButton';

import { DataContext } from '../../AdminFormView';
import { Language } from '@AppRoot';


type FieldResponse = Responses.Default & { field?: API.Field };

const handlePostField = (
  kc : KC,
  payload: API.PayloadType,
  id_or_name?: number | string | undefined
) => handler<FieldResponse>({
  method: Boolean(id_or_name) ? 'PUT' : 'POST',
  path: `/v1/manage/field/${id_or_name ?? ''}`,
  payload,
}, kc);

// type Option = {value: string, label: string};

// type Translations = {
//   et: string;
//   en?: string;
//   ru?: string;
// }

// type FieldSettings = {
//   labelTexts?: Translations;
//   options?: string[] | Option[];
//   helpText?: string;
//   helpTexts?: Translations;
//   errorText?: string;
//   errorTexts?: Translations;
//   tooltipText?: string;
//   tooltipTexts?: Translations;
//   placeholderText?: string;
//   placeholderTexts?: Translations;
// }
// interface FieldType {
//   type: string;
//   name: string;
//   label: string;
//   settings: FieldSettings;
// }

const languagesOptions = [
  {value: 'et', label: 'eesti', before: 'EST'},
  {value: 'en', label: 'inglise', before: 'ENG'},
  {value: 'de', label: 'saksa', before: 'GER'},
  {value: 'ru', label: 'vene', before: 'RUS'},
];
// field values for displaying extra options field
const optionsFields = ['select', 'multi-select', 'checkbox', 'radio'];
// fields that require at least one value
const oneOrMore = [ 'select', 'multi-select', 'checkbox' ];
// fields that require at least TWO value
const twoOrMore = [ 'radio' ];

// Regular expression to match a slug-like value
const slugRegex = /^[a-zA-Z0-9._-]+$/;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 22, offset: 1 },
};

const generateSlug = (name: string) => {
  return name.toLowerCase().replace(/ /g, '_').replace(/[^\w-]+/g, '');
}

interface ManageFieldProps {
  isAdmin?: boolean;
  onCancel?: () => void;
}

export const ManageFieldPage: React.FC<ManageFieldProps> = (props) => {
  const [action, setAction] = React.useState<API.ActionType>('new');
  const [suggestedSlug, setSuggestedSlug] = React.useState<string>('');
  const [isReady, setIsReady] = React.useState<boolean>(false);
  
  const oldFieldValues = React.useRef<API.Field>();
  const initialFieldValues = React.useRef<API.Field|undefined>();

  const { forms, fields, isLoading, setLoading} = React.useContext(DataContext);

  const { keycloak } = useKeycloak();
  const { fieldName } = useParams();
  const { t, i18n } = useTranslation();
  const nav = useNavigate();
  
  const loaderData = useLoaderData() as FieldResponse;

  const { sm } = Grid.useBreakpoint();
  const [form] = Form.useForm();

  const name = Form.useWatch('name', form) || '';
  const fieldType = Form.useWatch('type', form) || '';
  const fieldLabel = Form.useWatch('label', form) || '';
  const selectedLanguages = Form.useWatch('languages', form);

  const isMounted = React.useRef(false);
  
  React.useEffect(() => {
    if (fieldName && !['new', 'add', 'create'].includes(fieldName)) {
      const oldValues = fields.find(f => f.name === fieldName);
      if (oldValues) {
        oldFieldValues.current = oldValues as API.Field;
        form.setFieldsValue(oldValues);
        initialFieldValues.current = form.getFieldsValue();
        setAction('update');

        // Set language selections based on existing settings
        const existingLangs = Object
                               .keys(oldValues.settings?.labelTexts?.[i18n.language as Language] || {})
                               .filter(lang => languagesOptions.some(option => option.value === lang));
        form.setFieldsValue({ languages: existingLangs });
      }
    }
  }, [fieldName, fields, form]);

  const submitForm = (values: API.NewForm) => {
    setLoading(true);

    const payload = {
      action,
      newValues: values,
      ...(oldFieldValues ? {oldValues: oldFieldValues.current} : {})
    };
    
    handlePostField(keycloak!, payload, initialFieldValues?.current?.id )
      .then((res) => {
        if (res.success || res.field?.id) {
          notification.success({ message: t('forms.success.default','Form submitted successfully') });
        nav('/manage/forms/')
        }
      }).finally(() => setLoading(false));
  };
  
  const handleInitialValues = () => {
    const oldValues = fields.find(f => f.name == fieldName || String(f.id) == fieldName)
    if (oldValues) {
      oldFieldValues.current = oldValues as API.Field;
      form.setFieldsValue(oldValues);
      setAction('update')
    }
  };
  
  const validateSlug = async (_: any, value: string) => {
    if (!value) return Promise.resolve();
    console.log(oldFieldValues, initialFieldValues)
    const existingForm = fields.some(
      (field) => field.name === value && field.id !== initialFieldValues.current?.id
    );

    return existingForm
            ? Promise.reject(new Error('Nimi on juba võetud'))
            : Promise.resolve();
  };

  
  const handleAutoSlugValue = async () => {
    if (!name) {
      form.setFieldsValue({ name: suggestedSlug });
    }
  
    // Trigger validation here if needed
  };
  
  React.useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      if ( fieldName && !['new','add','create'].includes(fieldName) ) {
        // handleInitialValues()
      }
    }

    return () => {
      console.info('FormCreator unmounted');
    };
  }, []);

  React.useEffect(() => {
    if (fieldLabel) {
      const slug = generateSlug(fieldLabel);
      setSuggestedSlug(slug);
    } else {
      setSuggestedSlug('');
    }
  }, [fieldLabel]);

  React.useEffect(() => {
    if (!isLoading) {
      if ( fieldName && !['new','add','create'].includes(fieldName) ) {
        handleInitialValues()
      }
    }
  }, [isLoading]);


  const langPrefix = (lang: string) => (
    <Typography.Text
      strong
      type='secondary'
      style={{fontSize:'.8em'}}
    >
      {t(lang, lang)}
    </Typography.Text>
  )

  const onFill = () => {
    const allFields = form.getFieldsValue();
    console.log(allFields);
    Object.entries(allFields).map(([field,value]) => typeof value !== 'object' && form.setFieldValue(field, 'auto-fill'))
  };
  
  React.useEffect(() => {
    if (name && fieldType && fieldLabel) {
      setIsReady(
        name?.length > 2 &&
        fieldType?.length > 2 &&
        fieldLabel?.length > 2
      );
    }
  }, [name, fieldType, fieldLabel]);

  // Assuming 'loaderData' has the user data structure needed for the form
  React.useEffect(() => {
    if (loaderData && 'field' in loaderData) {
      initialFieldValues.current = loaderData.field;
      setAction('update');
      form.setFieldsValue(loaderData.field);
      if (loaderData.field?.settings) {
        const existingLangs = Object.keys(
          loaderData.field?.settings?.labelTexts || {}
        ).filter(
          lang => languagesOptions.some(o => o.value === lang)
        );
        form.setFieldValue( 'languages', existingLangs );
      }
    }
  }, [loaderData, form]);

  return (
    <>
      {/* <DividerTitle title={t(`forms.title.${action}Form`)} /> */}

      <Divider>{t(`forms.title.${action === 'add' ? 'new': action}Field`)}</Divider>

      <Form
        {...layout}
        form={form}
        name='manage-field-form'
        layout='vertical'
        onFinish={submitForm}
        initialValues={initialFieldValues.current}
        // onFieldsChange={onFieldsChange}
        style={{ maxWidth: 800, margin:'0 auto' }}
      >
        {/*
        <Form.Item
          name="id"
          label={t('forms.label.fieldId', 'Sisestus välja id')}
          rules={[{ required: false }]}
        >
          <Input readOnly variant="borderless" />
        </Form.Item>
        */}

        <Form.Item
          name="languages"
          label={t('forms.label.fieldLanguages')}
          rules={[{ required: false }]}
        >
          <Select
            allowClear
            mode='multiple'
            defaultActiveFirstOption
            // defaultValue={languagesOptions[0].value}
            options={languagesOptions}
          />
        </Form.Item>

        <Form.Item
          // label={t('forms.label.fieldLabel')}
          // rules={[{ required: true }]}
          wrapperCol={{span:24}}
        >
          <Form.Item
            name={['label']}
            label={t('forms.label.fieldLabel')}
            labelCol={{span:24}}
            wrapperCol={{span:22, offset:1}}
            rules={[{ required: true }]}
            style={{marginBottom:10}}
          >
            <Input
              // prefix={selectedLanguages?.length > 0 && langPrefix(t('forms.label.default'))}
              prefix={langPrefix(t('forms.label.default'))}
              required
            />
          </Form.Item>
          {
            languagesOptions?.map(lang => (
              <Form.Item
                key={lang.value}
                // label={t(`forms.label.${lang.value}`, lang.before)}
                name={['settings', `labelTexts`, lang.value]}
                hidden={!selectedLanguages?.includes(lang.value)}
                // labelCol={{span:4}}
                wrapperCol={{span:22, offset:1}}
                style={{marginBottom:10}}
              >
                <Input
                  multiple
                  autoCapitalize='on'
                  prefix={langPrefix(lang.before)}
                  // style={{marginBottom:10}}
                />
              </Form.Item>
            ))
          }
        </Form.Item>

      <Form.Item
        name='name'
        label={t('forms.label.slug')}
        tooltip={t('forms.tooltip.slug')}
        validateTrigger='onBlur'
        required={true}
        rules={[
          { required: true, message: t('forms.text.error.slug') },
          { validator: validateSlug },
        ]}
        // help={suggestedSlug && `Suggested slug: ${suggestedSlug}`}
      >
        <Input
          placeholder={suggestedSlug}
          autoComplete='false'
          onBlur={handleAutoSlugValue}
          required={true}
        />
      </Form.Item>

      <Form.Item
          name="type"
          label={t('forms.label.fieldType')}
          rules={[{ required: true }]}
          initialValue={settings.defaultFieldTypeOptions[0].value}
        >
          <Select
            allowClear
            defaultActiveFirstOption={true}
            options={settings.defaultFieldTypeOptions.map(i => ({
              value: i.value, label: t(`forms.option.${i.label}`)
            }))}
            // defaultValue={settings.defaultFieldTypeOptions[0].value}
            // placeholder="Select a question field type."
          />
        </Form.Item>
     
        <Form.Item
          label={t('forms.label.fieldOptions')}
          // labelCol={{ span: 4 }}
          shouldUpdate={(prev, current) => prev.type !== current.type}
          hidden={!optionsFields.includes(fieldType)}
        >
          { optionsFields.includes(fieldType) && (
            <AddOptions
              name={['settings', 'options']}
              forField={'fieldType'}
              // rules={[{ validator: listRenderValidator(fieldType) }]}
            />
          )}
        </Form.Item>

      <Form.Item
        label={t('forms.label.fieldTooltipText')}
        tooltip='Vihje tekst kuvatakse siin, kas vastav keel või vaike väärtus.'
      >
        <Form.Item
          noStyle
          name={['settings', `tooltipText`]}
        >
          <Input
            // prefix={selectedLanguages?.length > 0 && langPrefix(t('forms.label.default'))}
            prefix={langPrefix(t('forms.label.default'))}
            style={{marginBottom:10}}
          />
        </Form.Item>
        {
          languagesOptions?.map(lang => (
            <Form.Item
              noStyle
              key={lang.value}
              name={['settings', `tooltipTexts`, lang.value]}
              hidden={!selectedLanguages?.includes(lang.value)}
              labelCol={{span:4}}
              wrapperCol={{span:20}}
            >
            <Input
              multiple
              autoCapitalize='on'
              prefix={langPrefix(lang.before)}
              style={{marginBottom:10}}
            />
          </Form.Item>
          ))
        }
      </Form.Item>


      <Form.Item
        label={t('forms.label.fieldPlaceholderText')}
      >
        <Form.Item
          noStyle
          name={['settings', `placeholderText`]}
        >
          <Input
            placeholder='Kohatäide paikneb sisestus kasti sees.'
            prefix={selectedLanguages?.length > 0 && langPrefix(t('forms.label.default'))}
            style={{marginBottom:10}}
          />
        </Form.Item>
        {
          languagesOptions?.map(lang => (
            <Form.Item
              noStyle
              key={lang.value}
              name={['settings', `placeholderTexts`, lang.value]}
              hidden={!selectedLanguages?.includes(lang.value)}
              labelCol={{span:4}}
              wrapperCol={{span:20}}
            >
            <Input
              multiple
              autoCapitalize='on'
              prefix={langPrefix(lang.before)}
              style={{marginBottom:10}}
            />
          </Form.Item>
          ))
        }
      </Form.Item>

      <Form.Item
        label={t('forms.label.fieldHelpText')}
        help='Abitekst paikneb sisestus välja all.'
      >
        <Form.Item
          noStyle
          name={['settings', `helpText`]}
          help='Abitekst paikneb siin.'
        >
          <Input
            prefix={selectedLanguages?.length > 0 && langPrefix(t('forms.label.default'))}
            style={{marginBottom:10}}
          />
        </Form.Item>
        {
          languagesOptions?.map(lang => (
            <Form.Item
              noStyle
              key={lang.value}
              name={['settings', `helpTexts`, lang.value]}
              hidden={!selectedLanguages?.includes(lang.value)}
              labelCol={{span:4}}
              wrapperCol={{span:20}}
            >
            <Input
              multiple
              autoCapitalize='on'
              prefix={langPrefix(lang.before)}
              style={{marginBottom:10}}
            />
          </Form.Item>
          ))
        }
      </Form.Item>

      <Form.Item
        label={t('forms.label.fieldErrorText')}
      >
        <Form.Item noStyle name={['settings', `errorText`]}>
          <Input
            prefix={selectedLanguages?.length > 0 && langPrefix(t('forms.label.default'))}
            style={{marginBottom:10}}
          />
        </Form.Item>
        {
          languagesOptions?.map(lang => (
            <Form.Item
              noStyle
              key={lang.value}
              name={['settings', `errorTexts`, lang.value]}
              hidden={!selectedLanguages?.includes(lang.value)}
              labelCol={{span:4}}
              wrapperCol={{span:20}}
              extra='yes'
            >
            <Input
              multiple
              autoCapitalize='on'
              prefix={langPrefix(lang.before)}
              style={{marginBottom:10}}
            />
          </Form.Item>
          ))
        }
      </Form.Item>

      <Divider style={{marginTop:0}}/>

      <Form.Item noStyle wrapperCol={{span:24}}>
        <Flex vertical={!sm} justify={'space-between'}>
          <Space size='middle' direction='vertical'>
              { props.onCancel && (
                <Button
                  block
                  shape='round'
                  htmlType="button"
                  onClick={props.onCancel}
                >
                  {t('forms.btn.cancel')}
                </Button>
              )}
              <Button
                block
                type="text"
                shape='round'
                htmlType="reset"
                onClick={()=> form.resetFields()}
              >
                {t('forms.btn.reset')}
              </Button>
              <Button
                block
                type='link'
                shape='round'
                onClick={() => nav(-1)}
              >
                {t(`buttons.back`)}
              </Button>
              { true && (// props.isAdmin && (
                <Button
                  block
                  type="link"
                  shape='round'
                  htmlType="button"
                  onClick={onFill}
                >
                  {t('forms.btn.fillForm', 'Fill form')}
                </Button>
              )}
          </Space>
          <Space>
            action: {action}
            {
              action == 'update' && (
                <DeleteButton
                  block
                  item='field'
                  path='field'
                  shape='round'
                  hidden={action == 'update'}
                  onDelete={console.log}
                  record={{ id: form.getFieldValue('id') }}
                />
              )
            }
          </Space>
          <Space size='middle' direction='vertical'>
            <Button
              block
              type={isReady ? 'primary' : 'default'}
              shape='round'
              htmlType="submit"
              loading={isLoading}
            >
              {t(`forms.btn.${action ?? 'create'}`)}
            </Button>
            {/* <Button type="default" block onClick={openPreviewModal} icon={<EyeOutlined />}>
              Preview Field
            </Button> */}
          </Space>
        </Flex>
      </Form.Item>

    </Form>

    {/* <Modal
      title="Field Preview"
      open={previewVisible}
      onCancel={() => setPreviewVisible(false)}
      footer={null}
    >
      {
        // Render field preview here based on fieldPreview state
      }
      <p>{JSON.stringify(fieldValues)}</p>
      {
        // Implement a more sophisticated preview renderer as needed
      }
    </Modal> */}

    </>
  );
};
