import Keycloak from 'keycloak-js';
import React from 'react';

export type KC = Keycloak;

interface KeycloakState {
  keycloak: Keycloak | undefined;
  userData: Keycloak.KeycloakProfile | undefined;
  authenticated: boolean;
}

const KeycloakContext = React.createContext<KeycloakState>({
  keycloak: undefined,
  userData: undefined,
  authenticated: false,
});

/**
 * Custom React Hook that provides access to the Keycloak instance, user profile, authentication status and a function to retrieve a valid token.
 * This hook must be used within a `ReactKeycloakProvider`.
 *
 * @returns {object} An object containing:
 * - `keycloak`: The current Keycloak instance or null
 * - `userData`: The user's profile information or null
 * - `authenticated`: The user's authentication status (boolean)
 *
 * @throws {Error} Will throw an error if used outside of a `ReactKeycloakProvider`
 *
 * @example
 * ```jsx
 * import { useKeycloak } from '<path to this file>/keycloak';
 *
 * function YourComponent() {
 *   const { keycloak, authenticated, userData } = useKeycloak();
 *
 *   // Your component code...
 * }
 * ```
 */
export function useKeycloak(): KeycloakState {
  const context = React.useContext(KeycloakContext);

  if (context === undefined) {
    throw new Error('useKeycloak must be used within a ReactKeycloakProvider');
  }

  const { keycloak, authenticated, userData } = context;

  return { keycloak, authenticated, userData };
}

export default KeycloakContext;
