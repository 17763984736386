import { Card, Col, Row, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { UTLogo } from '@App/components/Logo';

const appTitleStyles = {
  fontFamily: 'Rubik',
  color: '#2c5696',
  marginTop: '1rem'
}

const appSloganStyles = {
  fontFamily: 'Rubik'
}

const colStyles = {
  maxWidth: 600,
  textAlign: 'center',
  padding: '1rem 0 2rem',
} as React.CSSProperties

export function Hero() {
  const { t } = useTranslation();

  return (
    <Row justify='center'>
      <Col xs={24} className='site-hero' style={colStyles}>

        <UTLogo />
          
        <Typography.Title level={3} style={appTitleStyles}>
            {t('site.name')}
        </Typography.Title>
          
        <Typography.Paragraph style={appSloganStyles}>
          {t('site.description')}
        </Typography.Paragraph>

      </Col>
    </Row>
  );
}
