import { Button, Divider, List, Modal, Row, Typography, Col, Grid, message, Card, Spin } from 'antd';
import React from 'react';
import { EditFilled } from '@ant-design/icons';

import { handler } from '@App/settings/ApiHandler';
import { KC, useKeycloak } from '@App/settings/keycloak';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { formatDate } from '@App/components/helpers';
import { hasAccess, permissions } from '@App/settings/navigation';
import { ApplicationState, StateManager } from '@App/settings/StateManager';
import DynamicFormModal from '@App/components/forms/DynamicFormModal';
import { PublicFormsData } from './FormsView';


const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
function isValidUUID(uuid: string|undefined): boolean {
  return uuid ? uuidRegex.test(uuid) : false;
}
function checkUUID(uuid: string|undefined): string {
  if (uuid && isValidUUID(uuid)) {
    return uuid;
  }
  return '';
}

type FormsTypes = {
  forms: Form.FormType[],
  granted?: Form.FormType[],
  ttr?: Form.FormType[],
}

export const FormsPage: React.FC = () => {  
  const { t } = useTranslation('forms');
  const nav = useNavigate();
  
  const {sm, md} = Grid.useBreakpoint();
  const loaderData = useLoaderData() as API.GetForms;

  const renderFormItems = (form: Form.FormType) => (
    // <Card bordered={false}>
      <List.Item key={form.uuid}>

        <List.Item.Meta
          title={form.title}
          description={form.description ?? form.name}
        />

        <Button
          key={"fill-form-"+form.slug}
          type='link'
          shape='round'
          icon={<EditFilled />}
          onClick={()=> nav(form.slug ?? form.uuid)}
          block={!sm}
          className={'btn-border fill-form-'+form.slug}
        >
          {t('forms.btn.fillForm')}
        </Button>
      </List.Item>
    // </Card>
  )

  return (
    <>
      <Spin spinning={false} >
        {
          loaderData.ttr?.length > 0 && (
            <Row justify='center'>
              <Col 
                xs={{span:20, offset: 0}}
                md={{span:16, offset: 2}}
              >
                  <Typography.Title level={md ? 3 : 4} className='page-title'>
                    {t('titles.section.enterStudyData')}
                  </Typography.Title>
                  <List
                    itemLayout={sm ? 'horizontal' : 'vertical'}
                    dataSource={loaderData.ttr}
                    renderItem={renderFormItems}
                    style={{marginBottom: 50}}
                    />
              </Col>
            </Row>
        )}

        {
          loaderData.granted?.length > 0 && (
            <Row justify='center'>
              <Col 
                xs={{span:20, offset: 0}}
                md={{span:16, offset: 2}}
              >
                  <Typography.Title level={md ? 3 : 4} className='page-title'>
                    {t('titles.section.enterStudyData', 'maaratud')}
                  </Typography.Title>
                  <List
                    itemLayout={sm ? 'horizontal' : 'vertical'}
                    dataSource={loaderData.granted}
                    renderItem={renderFormItems}
                    style={{marginBottom: 50}}
                    />
              </Col>
            </Row>
        )}

        <Row justify='center'>
          <Col
            xs={{span:20, offset: 0}}
            md={{span:16, offset: 2}}
          >
            <Typography.Title level={md ? 3 : 4} className='page-title'>
              {t('titles.section.enterProjectData')}
            </Typography.Title>
            <List
              itemLayout={sm ? 'horizontal' : 'vertical'}
              dataSource={loaderData.forms}
              renderItem={renderFormItems}
            />  
          </Col>
        </Row>
      </Spin>
    </>
  );
};
