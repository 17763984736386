import { ReloadOutlined, ArrowLeftOutlined, EditFilled, QuestionCircleFilled, QuestionOutlined, PlusOutlined} from '@ant-design/icons';
import { handler } from '@App/settings/ApiHandler';
import { KC, useKeycloak } from '@App/settings/keycloak';
import { Button, Divider, List, message, Space, Table, Tag, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';

import { formatDate } from '@App/components/helpers';

import DeleteButton from '../../components/buttons/DeleteButton';
import EditButton from '../../components/buttons/EditButton';
import ExpandableList from '@App/components/tables/ExpandableList';



const renderActionButtons = (_: string, record: Responses.ProjectData) => (
  <Space size='small'>
    <EditButton record={record} />
    <DeleteButton path='forms' record={record} item={'entity'} />
  </Space>
);

const columns: ColumnsType<Responses.ProjectData> = [
  {
    title: 'ID',
    dataIndex: 'key',
    key: 'key',
    width: 50,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (status) => {
      const color =
        status === 'draft'
          ? 'gold'
          : status === 'active'
          ? 'green'
          : status === 'deleted'
          ? 'red'
          : 'cyan';
      return <Tag color={color}>{status.toUpperCase()}</Tag>;
    },
    width: 100,
  },
  {
    title: 'Action',
    key: 'action',
    render: renderActionButtons,
    width: 100,
  },
];


type Res = {
  error?: string;
  success?: string;
  faculties: Responses.FacultyType[];
  forms: Form.FormType[];
  ttr: Responses.TTRData[];
  items: Responses.TTRData[];
  projects: Responses.ProjectData[];
}

type UpdateRes = {
  forms: Form.FormType[];
  faculties: Responses.FacultyType[];
  item?: Responses.TTRData;
  items?: Responses.TTRData[];
  error?: string;
  success?: string;
}

const getTtrData = (kc: KC) => handler<Res>({
  method: 'GET',
  path: '/v3/ttr/',
}, kc!)

type SuccessRes = {
  items: Responses.TTRData[];
  usersGroups?: API.GroupType
}
const getUserTTR = (kc: KC) => handler<Res>({
  method: 'GET',
  path: '/v3/ttr/',
}, kc!)

const postUserTTR = (kc: KC) => handler<UpdateRes>({
  method: 'GET',
  path: '/v3/ttr/',
}, kc!)

export const UserFaculties: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [ttrData, setTtrData] = React.useState<Responses.TTRData[]>([]);
  const [projects, setProjects] = React.useState<Responses.ProjectData[]>([]);
  const [formsData, setFormsData] = React.useState<Form.FormType[]>([]);
  const selectedForm = React.useRef<Form.FormType>();
  const userFaculties = React.useRef< Responses.FacultyType[]>();

  const {keycloak} = useKeycloak();

  const handleFillForm = (form: Form.FormType) => {
    selectedForm.current = form;
    console.log(form);
  };

  const get = () => {
    const abortController = new AbortController();
    setIsLoading(true);

    getTtrData(keycloak!)
      .then((data) => {
        if (data.forms) {
          setFormsData(data.forms);
        }
        if (data.projects) {
          setProjects(data.projects);
        }
        if (data.items) {
          setTtrData(data.items);
        }
        if (data.faculties) {
          userFaculties.current = data.faculties;
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    
    return () => {
      abortController.abort();
    };
  };

  const getNew = () => {
    const abortController = new AbortController();
    setIsLoading(true);

    getUserTTR(keycloak!)
      .then((data) => {
        console.log(data);
        if (data.items) {
          setTtrData(data.items);
        }
        // if (data.forms) {
        //   setFormsData(data.forms);
        // }
        // if (data.projects) {
        //   setProjects(data.projects);
        // }
        // if (data.ttr) {
        //   setTtrData(data.ttr);
        // }
        // if (data.faculties) {
        //   userFaculties.current = data.faculties;
        // }
      })
      .catch((err)=>{
        message.error('Failed')
      })
      .finally(() => {
        setIsLoading(false);
      });
    
    return () => {
      abortController.abort();
    };
  };

  const printDates = ({updated_at, created_at}: Responses.TTRData) => {
    if (updated_at) {
      return 'Last updated at ' + formatDate( updated_at)
    }
    return 'Created at ' + formatDate( created_at)
  }

  React.useEffect(() => {
    const cleanup = get();
    
    return () => {
      cleanup();
    };
  }, []);

  return (
    <div>

      <Space>
        {/* <Typography.Title level={3}>
          Table with mock data
        </Typography.Title> */}
        <Button.Group>
          <Button onClick={get}>Refresh</Button>
        </Button.Group>
        <Button.Group>
          <Button onClick={getNew}>Refresh new endpoint</Button>
        </Button.Group>
      </Space>

      {/* <Table rowKey={'key'} columns={columns} dataSource={projects} /> */}

      <Divider >
        <Typography.Title level={1}>TTR</Typography.Title>
      </Divider>

      {/* <List
        loading={isLoading}
        itemLayout="horizontal"
        dataSource={ttrData}
        renderItem={form => (
          <List.Item
            key={form.uuid}
            actions={[
              <Button
                key="fill-form"
                type='primary'
                icon={<EditFilled />}
                onClick={()=> handleFillForm(form)}
              >
                edit
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={(form.project_data && form.project_data['formName'] as string) ?? 'n/a'}
              description={printDates(form)}
            />
          </List.Item>
        )}
        style={{ maxWidth: 991 }}
      /> */}

      <ExpandableList
        head={formsData[0]}
        listItems={ttrData}
        faculties={userFaculties.current ?? []}
        isLoading={false}
        hiddenColumns={[]}
        onUpdate={function (record: Responses.TTRData): void {
          throw new Error('Function not implemented.');
        } }
      />

      <Divider >
        <Typography.Title level={1}>
          Projects
        </Typography.Title>
      </Divider>

      <List
        loading={isLoading}
        itemLayout="horizontal"
        dataSource={projects}
        renderItem={form => (
          <List.Item
            key={form.uuid}
            actions={[
              <Button
                key="fill=form"
                type='primary'
                icon={<EditFilled />}
                onClick={()=> handleFillForm(form)}
              >
                edit
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={`${form.project_data?.title ?? 'n/a'}`}
              description={printDates(form)}
            />
          </List.Item>
        )}
        style={{ maxWidth: 991 }}
      />

      <Divider >
        <Typography.Title level={1}>Forms</Typography.Title>
      </Divider>

      <List
        itemLayout="horizontal"
        dataSource={formsData}
        renderItem={form => (
          <List.Item
            key={form.uuid}
            actions={[
              <Button
                key="fill=form"
                type='primary'
                icon={<EditFilled />}
                onClick={()=> handleFillForm(form)}
              >
                edit
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={form.title}
              description={form.description ?? form.name}
            />
          </List.Item>
        )}
        style={{ maxWidth: 991 }}
      />
    </div>
  );
};
