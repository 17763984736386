import { theme } from 'antd';
import React from 'react';


/**
 * Defined applications `LIGHT` theme colors
 */
const lightThemeColors = {
  algorithm: theme.defaultAlgorithm,
  components: {
    Table: {
      headerBg: '#2c5696',
      // rowHoverBg: '#1677ff',
      rowHoverBg: '#deebfc',
      headerColor: '#ffffff',
      headerSortHoverBg: '#284c83',
      // headerSortActiveBg: '#193661',
      headerSortActiveBg: '#193661',
      fixedHeaderSortActiveBg: '#284c83',
      algorithm: true,
      adminColor: '#ff0',
      footerBg: '#1919191a'
    },
    Select: {
      /* here is your component tokens */
    },
    // Input: {
    //   height: 80, // Increased input height
    //   fontSize: 18, // Increased font size
    //   padding: '12px 16px', // Increased padding
    // },
    // Button: {
    //   height: 48, // Increased button height
    //   fontSize: 18, // Increased font size
    //   padding: '12px 24px', // Increased padding
    // },
    // Form: {
    //   itemMarginBottom: 40, // Increased margin between form items
    // }
  },
  token: {
    fontSize: 16,
    colorPrimary: '#1677ff',
    // darkItemBg: '#ff0000',
    itemHoverColor: '#ffffff',
    // darkSubMenuItemBg: '#ff0000',
    colorBgLayout: '#f0f0f0',
    // colorInfoBg: '',
    adminColor: '#ff0'
  },
};


/**
 * Defined applications `DARK` theme colors
 */
const darkThemeColors = {
  algorithm: theme.darkAlgorithm,
  components: {
    Table: {
      headerBg: '#2c5696',
      // rowHoverBg: '#ff0000',
      headerColor: '#ffffff80',
      algorithm: true,
      adminColor: '#f00',
      footerBg: '#ffffff1a'
    },
  },
  token: {
    fontSize: 16,
    controlOutline: '#1677ff40',
    colorPrimary:  '#1677ff',//'#00b96b',
    colorBgLayout: '#111111',
    colorText: '#f5f5f5',
    colorBgElevated: '#222',
    colorBgContainer: '#232425',
    colorTextTertiary: '#ccc',
    colorTextLabel: '#f9f9f9',
    colorTextPlaceholder: '#ffffff40',
    colorBorder: '#ffffff42',
    headerSortHoverBg: '#284c83',
    headerSortActiveBg: '#113163',
    colorBorderSecondary: '#ffffff21',
    // colorPrimaryHover: '#f00'
    // colorWarningBg: '',
    // colorErrorBg:'',
    darkItemBg: '#ff0000',
    // colorInfoBg: '',
    darkItemHoverColor: '#ff0000',
    itemHoverColor: '#ff0000',
    darkSubMenuItemBg: '#ff0000',
    adminColor: '#f00'
  },
};


export {
  lightThemeColors,
  darkThemeColors
}
