import React from 'react';
import { Button, ButtonProps, Grid, message, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { handler } from '../../settings/ApiHandler';
import { KC, useKeycloak } from '@App/settings/keycloak';
import { useTranslation } from 'react-i18next';


const apiPath = '/v1';

interface DeleteButtonProps {
  path: string;
  item: string;
  record: any;
  iconOnly?: boolean;
  onDelete?: (record: any) => void;
}

type DeleteResponse = {
  success?: string;
  error?: string;
}

const handleDeleteRequest = (path: string, id: string, kc:KC) => handler<DeleteResponse>({
  path: path,
  method: 'PUT',
  payload: { action: 'delete', id }
}, kc);

const DeleteButton: React.FC<DeleteButtonProps & ButtonProps> = (props) => {
  const {
    path,
    record,
    onDelete,
    type,
    iconOnly = false,
    ...rest
  } = props;
  const [open, setOpen] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const {keycloak} = useKeycloak();
  const {t} = useTranslation();
  const { md } = Grid.useBreakpoint();
  // Translate the item first
  const translatedItem = t('forms.actions.' + props.item);
  
  // Construct the translation key
  const confirmText = t('forms.actions.confirm.delete', { item: translatedItem });

  const showPopconfirm = () => {
    setOpen(true);
  };
  
  const handleOk = () => {
    setConfirmLoading(true);
    handleDeleteRequest( path, record.id, keycloak!)
      .then( (res: DeleteResponse) => {
        onDelete && onDelete(res || record);
        setOpen(false);
      
      }).then( (err) => {
        console.log('Error: ',err)
      }).finally(()=>{
        setConfirmLoading(false);
      })
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Popconfirm
      title={confirmText}
      okText={t('forms.label.yes')}
      cancelText={t('forms.label.no')}
      // description="Confirm modal body text goes here."
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleCancel}
      >
      <Button
        danger
        type={type}
        icon={<DeleteOutlined />}
        onClick={showPopconfirm}
        {...rest}
      >
        { !iconOnly && ( md && t('forms.btn.delete')) }
      </Button>
    </Popconfirm>
  );
};

export default DeleteButton;