import React from 'react';
import {
  MailOutlined,
  DownSquareFilled,
  RightSquareFilled,
  EditOutlined,
  UserOutlined,
  CustomerServiceOutlined,
  CommentOutlined,
  DeleteOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import {
  Avatar,
  Button,
  Col,
  Descriptions,
  Divider, Flex,
  FloatButton, List,
  message,
  Row, Skeleton,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  theme,
  Tooltip,
  Typography
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {formatDate, formatDateTime, formatDateValue} from '@App/components/helpers';
import { handler } from '@App/settings/ApiHandler';
import { KC, useKeycloak } from '@App/settings/keycloak';
import { useTranslation } from 'react-i18next';
import DeleteButton from '@App/components/buttons/DeleteButton';
import StatusDropdown, { statusItems } from '@App/components/buttons/StatusButton';
import { ColumnFilterItem, ColumnType } from 'antd/lib/table/interface';
import { hasAccess, permissions } from '@App/settings/navigation';
import { ApplicationState, StateManager } from '@App/settings/StateManager';
import { hasDependenceValue, renderValue } from './TableHelpers';
import dayjs from "dayjs";


interface ExpandRowContentProps {
  record: Responses.ResearchData;
  onEdit: (record: Responses.ResearchData) => void;
  onAdminEdit?: (record: Responses.ResearchData) => void;
  onDelete?: (record: Responses.ResearchData) => void;
  formFields?: API.Field[];
  className?: string;
  isAdmin?: boolean;
}

export const ResearchExpandRowContent: React.FC<ExpandRowContentProps> = ({
  record, onEdit, onAdminEdit, formFields, className, onDelete, isAdmin = false
}) => {
  const [newStatus, setNewStatus] = React.useState<string>(record.status);
  const [filled, setFilled] = React.useState<boolean>(record.form_filled!);
  const { state } = React.useContext<StateManager>(ApplicationState);
  const { t } = useTranslation();
  const { token } = theme.useToken();

  // Safely handle if project_data is null or undefined
  if (!('project_data' in record || 'form_data' in record)) {
    return <p>No data available</p>;
  }

  const handleEditClick = () => {
    // const formName = record.project_data && 'formName' in record.project_data ?  record.project_data['formName']+'' : 'Form not found';
    onEdit( record );
  };
  
  const handleDeleteClick = (payload:{success?:string}) => {
    // const formName = record.project_data && 'formName' in record.project_data ?  record.project_data['formName']+'' : 'Form not found';
    if (payload.success) {
      message.success(payload.success)
    }
    onDelete && onDelete(record)
  };

  const handleAdminEditClick = () => {
    onAdminEdit && onAdminEdit( record );
  };

  return (
    <div
      key={record.uuid}
      className={className}
      style={{
        display: 'block',
        position: 'sticky',
        maxWidth: '90svw',
        width: state.isSidebarCollapsed ? '88svw' : '34vw',
        left: 0,
      }}
    >

      <Flex vertical style={{ padding: '0 1.5rem .5rem', width: '100%'}}>
        {/* <Descriptions
          layout='vertical'
          column={1}
          style={{ width: '100%' }}
        >
          {
            Object.entries('form_data' in record && record.form_data)
            .sort((a,b)=> b[0].localeCompare(a[0]))
            .map(([key, value]) => (
              <Descriptions.Item
                key={key}
                label={formFields?.find(i => i.name == key)?.label ?? t(`forms.label.${key}`)}
                labelStyle={{fontStyle: 'italic'}}
              >
                <Typography.Paragraph style={{ marginLeft: 8 }}>
                  {renderValue(value, '', false)}
                </Typography.Paragraph>
              </Descriptions.Item>
            ))
          }
        </Descriptions>

        <Divider>
          <Typography color='red'>
            uus j2rjekord
          </Typography>
        </Divider> */}

        <Descriptions
          layout='vertical'
          column={1}
          style={{ width: '100%' }}
          labelStyle={{
            fontWeight: 600,
            color: token.colorText
          }}
        >
          {
            formFields?.length &&
            formFields
            .sort((a,b)=> (a.order || a.settings.order) - (b.order || b.settings.order))
            .map((field, fieldIdx) => {
              const value = 'form_data' in record && record.form_data[field.name];
              const hasDependencies = field.settings.dependencies;
              const hasParent = field.settings.dependencies?.dependsOn.some(i => i in record.form_data);
              const hasValue = value && field.settings.dependencies?.values.includes(value);
              const label = field.label ?? t(`forms.label.${field.name}`, field.label);

              if (hasDependencies && value?.length < 2) {
                // console.log('skipping this depencei', hasDependencies, value, field)
                return null;
              }

              return (
                <Descriptions.Item
                  key={fieldIdx}
                  label={label.length > 1 ? label : null}
                  // label={field.label ?? t(`forms.label.${field.name}`)}
                  labelStyle={{
                    display: label.length > 1 ? 'block' : 'none',
                    fontStyle: 'italic',
                    // outline: '2px solid red',
                    marginLeft: hasDependencies ? '1rem' : 0
                  }}
                  contentStyle={{
                    marginLeft: hasDependencies ? '1rem' : 0
                  }}
                  style={{
                    padding: 5
                  }}
                >
                  <Typography.Paragraph
                    style={{ marginLeft: 8 }}
                    title={field.name}
                  >
                    {/*{hasDependencies && ' * '}*/}
                    {
                      ['research_duration'].includes(field.name)
                        ? formatDateValue(value as string | string[] ?? '')
                        : renderValue(value, '', false)
                    }
                    {/*{renderValue(value, '', false)}{' '}*/}
                    {/*{field.required ? <Typography.Text color='red'>*</Typography.Text> : ' not required '}{' '}*/}
                    {/*{hasDependenceValue(record.form_data, field)}{' '}*/}
                    {/* {
                      field.settings.dependencies &&
                      record.form_data[field.settings.dependencies.dependsOn[0]]
                    }*/}
                    {/*{JSON.stringify(field.settings.dependencies, null, 2)}*/}
                  </Typography.Paragraph>
                </Descriptions.Item>
            )})
          }
        </Descriptions>

        {/*<List
          className="demo-loadmore-list"
          loading={false}
          itemLayout="horizontal"
          dataSource={
            formFields?.length &&
            formFields
            .sort((a,b)=> (a.order || a.settings.order) - (b.order || b.settings.order)) || []}
          renderItem={(item) => (
            <List.Item>
              <Skeleton avatar title={false} loading={item.loading} active>
                  <List.Item.Meta
                    title={
                      <Typography.Text
                        strong
                        type={'secondary'}
                        style={{
                          display: item.label.length > 1 ? 'block' : 'none',
                          fontStyle: 'italic',
                          // outline: '2px solid red',
                          marginLeft: item.settings?.dependencies ? '1rem' : 0
                        }}
                      >
                        {item.label.length > 1 ? item.label : null}
                      </Typography.Text>
                    }
                    description={
                      <Typography.Paragraph>
                        {
                          ['research_duration'].includes(item.name)
                              ? formatDateValue(record.form_data[item.name] as string | string[] ?? '')
                              : renderValue('form_data' in record && record.form_data[item.name], '', false)
                        }
                      </Typography.Paragraph>
                    }
                  />
              </Skeleton>
            </List.Item>
          )}
        />*/}

        <Divider/>

        <Descriptions layout='horizontal' column={1} style={{ width: '100%' }}>
          {record?.created_by?.length && (
            <Descriptions.Item span={1} label={t('table.column.createdBy')}>
              <Tooltip title={record?.created_by} placement="top">
                {record?.created_by || <UserOutlined />}
              </Tooltip>
            </Descriptions.Item>
            )
          }

          {
            hasAccess(permissions.admin, state.session.permissions) &&
              record?.shared_with?.length && (
              <Descriptions.Item span={1} label={t('table.column.sharedWith')}>
                <Avatar.Group style={{ marginLeft: 8 }}>
                  { record.shared_with_users?.map((usr, idx) => (
                    <Tooltip key={idx} title={usr.fullname} placement="top">
                      <Avatar
                        key={idx}
                        style={{ backgroundColor: usr.color || 'primary' }}
                      >
                        {usr.avatar || usr.username.substring(0, 3) || <UserOutlined />}
                      </Avatar>
                    </Tooltip>
                  ))}
                </Avatar.Group>
              </Descriptions.Item>
            )
          }
          
          <Descriptions.Item label={t('table.column.label.createdAt')}>
            <Typography.Paragraph style={{ marginLeft: 8 }}>
              { formatDate(record.created_at) }
            </Typography.Paragraph>
          </Descriptions.Item>

          {/* <Descriptions.Item label={t('label.editors')} contentStyle={{ marginLeft: 8 }}>
            <Avatar.Group >
              {
                Array.isArray(record.users)
                ? record.users.map( (u,i) => (
                  <Tooltip key={i} title={u} placement="top">
                    <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                  </Tooltip>
                ))
                : record.users
              }
            </Avatar.Group>
          </Descriptions.Item>

          <Descriptions.Item label={t('table.column.label.version')}>
            <Typography.Text style={{ marginLeft: 8 }}>
              { record.version }
            </Typography.Text>
          </Descriptions.Item> */}

          {
            hasAccess(permissions.admin, state.session.permissions) && (
              <Descriptions.Item label={t('table.column.lastUpdated')}>
                <Typography.Paragraph style={{ marginLeft: 8 }}>
                  { formatDate(record.updated_at) }
                </Typography.Paragraph>
              </Descriptions.Item>
            )
          }
        </Descriptions>
        
        <Space>
          <Button
            type={'primary'}
            shape={'round'}
            icon={<EditOutlined />}
            onClick={handleEditClick}
          >
            {t('forms.btn.update')}
          </Button>

          {/* <DeleteButton
            item='entry'
            iconOnly={false}
            
            path={`/v3/researchProject/${record.uuid}/delete`}
            onDelete={handleDeleteClick}
            record={record}
          /> */}
          {
              isAdmin && (
                  <>
                    <Button
                        type={'default'}
                        shape={'round'}
                        icon={<EditOutlined />}
                        onClick={handleAdminEditClick}
                    >
                      {t('forms.btn.setFilled')}
                    </Button>
                    {/*
              <Switch
                checkedChildren='täidetud'
                unCheckedChildren='täitmata'
                value={filled}
                onChange={setFilled}
              />
              <StatusDropdown
                initialValue={record.status}
                onChange={setNewStatus}
              />
              <Button
                type={(filled !== record.form_filled || newStatus !== record.status) ? 'primary' : 'default'}
                icon={<EditOutlined />}
                onClick={handleEditClick}
              >
                {t('forms.btn.save')}
              </Button>
              */}
                  </>
              )
          }
        </Space>


      </Flex>
    </div>
  );
};
